<template>
  <b-row class="mt-2">
    <b-col cols="12" md="4">
      <div v-if="tournament.tournamentCategories.length">
        <h5 class="mt-4 color-b">
          {{ $t("common.important_dates") }}
        </h5>
        <b-col class="mb-3">
          <b-row>
            <span>{{ $t("common.firstTournamentDate") }}:</span>
          </b-row>
          <b-row>
            <span> {{ localizeDate(tournament.firstTournamentDate) }}</span>
          </b-row>
        </b-col>

        <b-col class="mb-3">
          <b-row>
            <span>{{ $t("common.lastTournamentDate") }}:</span>
          </b-row>
          <b-row>
            <span> {{ localizeDate(tournament.lastTournamentDate) }}</span>
          </b-row>
        </b-col>

        <b-col class="mb-3">
          <b-row>
            <span>{{ $t("common.registrationOpenAt") }}:</span>
          </b-row>
          <b-row>
            <span> {{ localizeDate(tournament.registrationOpenAt) }}</span>
          </b-row>
        </b-col>

        <b-col class="mb-3">
          <b-row>
            <span>{{ $t("common.registrationCloseAt") }}:</span>
          </b-row>
          <b-row>
            <span> {{ localizeDate(tournament.registrationCloseAt) }}</span>
          </b-row>
        </b-col>

        <b-col class="mb-3">
          <b-row>
            <span>{{ $t("common.changesCloseAt") }}:</span>
          </b-row>
          <b-row>
            <span> {{ localizeDate(tournament.changesCloseAt) }}</span>
          </b-row>
        </b-col>
      </div>
      <div v-if="tournament.tournamentCategories.length">
        <h5 class="mt-4 color-b">
          {{ $t("common.category") }}
        </h5>
        <div
          class="p-0"
          v-for="category in tournament.tournamentCategories"
          :key="category.id"
        >
          {{ category.name }}
        </div>
      </div>

      <div>
        <h5 v-if="tournament.tournamentCategories.length" class="mt-4 color-b">
          {{ $t("common.type") }}
        </h5>
        <span class="p-0">
          {{ tournament.tournamentTypeName }}
        </span>
      </div>

      <div v-if="tournament.involvedOrganisations.length">
        <h5 class="mt-4 color-b">
          {{ $t("common.organisation") }}
        </h5>
        <b-table
          :fields="involvedOrganisationFields"
          :items="tournament.involvedOrganisations"
          show-empty
          borderless
          :empty-text="$t('common.no_records')"
          thead-class="d-none"
          small
          responsive
        >
          <template v-slot:cell(organisationName)="{ item }">
            <div>
              {{ item.organisationName }} <br />
              {{ item.address.address1 }}
              {{ item.address.houseNumber }}
              {{
                item.address.houseNumberExtension
                  ? item.address.houseNumberExtension
                  : ""
              }}<br />
              {{ item.address.zipCode }} {{ item.address.city }}
            </div>
          </template>
        </b-table>
      </div>

      <div v-if="tournament.website || tournament.generalEmailAddress">
        <h5 class="mt-4 color-b">{{ $t("common.contact") }}</h5>
        <a
          v-if="tournament.website"
          class="color-b"
          :href="`//${tournament.website}`"
          target="_blank"
          >{{ tournament.website }}</a
        >
        <div v-if="tournament.generalEmailAddress">
          {{ tournament.generalEmailAddress }}
        </div>
      </div>

      <div v-if="tournament.involvedPeople">
        <h5 class="mt-4 color-b">
          {{ $t("common.contact_persons") }}
        </h5>
        <b-table
          :fields="contactPersonFields"
          :items="tournament.involvedPeople"
          show-empty
          borderless
          :empty-text="$t('common.no_records')"
          thead-class="d-none"
          small
          responsive
        >
          <template
            v-slot:cell(roleName)="{ item }"
            class="float-left font-italic"
          >
            {{ item.roleName }}
          </template>
          <template v-slot:cell(name)="{ item }" class="float-left font-italic">
            {{ item.name }}
          </template>
        </b-table>
      </div>
      <div
        v-if="
          tournament.tournamentJudgeHead &&
            tournament.tournamentJudgeHead.fullName
        "
      >
        <h5 class="mt-4 color-b">{{ $t("common.head_of_judge") }}</h5>
        <div>
          {{ tournament.tournamentJudgeHead.fullName }}
        </div>
      </div>
    </b-col>
    <b-col class="color-b" cols="12" md="8">
      <div v-if="tournament.description">
        <h5 class="mt-4 color-b">
          {{ $t("common.description") }}
        </h5>
        <p class="mt-2 mb-4">{{ tournament.description }}</p>
        <div class="line" />
      </div>

      <div v-if="tournament.locations.length">
        <h5 class="mt-4 mb-2 color-b">
          {{ $t("common.location") }}
        </h5>
        <b-table
          :fields="locationFields"
          :items="tournament.locations"
          show-empty
          borderless
          small
          responsive
          class="mb-4"
          :empty-text="$t('common.no_records')"
        >
        </b-table>
        <div class="line" />
      </div>

      <div v-if="tournament.days.length">
        <h5 class="mt-4 mb-2 color-b">
          {{ $t("common.days") }}
        </h5>
        <b-col class="mb-4 p-1" style="color: #656565">
          <b-row class="pb-2" v-for="day in tournament.days" :key="day.id">
            <b-col cols="1">
              {{ getTranslatedDayName(day.dayName) }}
            </b-col>
            <b-col cols="2" class="ml-3">
              {{ `${formatDate(day.date, "dd-MM-yyyy")}` }}
            </b-col>
            <b-col cols="8">
              {{
                `${day.startTime.split(":")[0]}:${day.startTime.split(":")[1]}`
              }}
              {{
                day.endTime
                  ? ` - ${day.endTime.split(":")[0]}:${
                      day.endTime.split(":")[1]
                    }`
                  : ""
              }}
            </b-col>
          </b-row>
        </b-col>
        <div class="line" />
      </div>

      <div v-if="tournament.meetings.length">
        <h5 class="mt-4 mb-2">
          {{ $t("common.meetings") }}
        </h5>
        <b-table
          class="mb-4"
          :fields="meetingFields"
          :items="tournament.meetings"
          show-empty
          borderless
          responsive
          :empty-text="$t('common.no_records')"
          small
        >
          <template v-slot:cell(date)="{ item }">
            {{ formatDate(item.date, "dd-MM-yyyy") }}
          </template>
          <template v-slot:cell(startTime)="{ item }">
            {{
              item.startTime
                ? `${item.startTime.split(":")[0]}:${
                    item.startTime.split(":")[1]
                  }`
                : ""
            }}
          </template>
          <template v-slot:cell(endTime)="{ item }">
            {{
              item.endTime
                ? `${item.endTime.split(":")[0]}:${item.endTime.split(":")[1]}`
                : ""
            }}
          </template>
        </b-table>
        <div class="line" />
      </div>
      <div v-if="tournament.tournamentRankings.length">
        <h5 class="mt-4">
          {{ $t("common.rankings") }}
        </h5>
        <b-table
          v-if="tournament.tournamentRankings.length"
          class="mb-4"
          :fields="rankingFields"
          :items="tournament.tournamentRankings"
          show-empty
          borderless
          :empty-text="$t('common.no_records')"
          small
          responsive
        >
        </b-table>

        <div
          v-if="
            tournament.registrationRule &&
              tournament.registrationRule.additionalInformation
          "
          class="line"
        />
      </div>

      <div
        v-if="
          tournament.registrationRule &&
            tournament.registrationRule.additionalInformation
        "
      >
        <h5 class="mt-4 mb-2">{{ $t("common.additional_information") }}</h5>
        <p v-html="tournament.registrationRule.additionalInformation"></p>
      </div>
    </b-col>
  </b-row>
</template>

<script>

export default {
  props: {
    tournament: {},
  },
  data() {
    return {
      involvedOrganisationFields: [
        {
          label: "",
          key: "organisationName",
          thClass: "color-b",
          tdClass: "pl-0",
        },
      ],
      locationFields: [
        {
          label: this.$t("common.water"),
          key: "water",
          thClass: "color-b",
        },
        {
          label: this.$t("common.location"),
          key: "name",
          thClass: "color-b",
        },
        {
          label: this.$t("common.distance_in_meter"),
          key: "distanceInMeter",
          thClass: "color-b",
        },
      ],
      dayFields: [
        {
          label: "",
          key: "day",
          tdClass: "color-b",
          thStyle: "width: 20%;",
        },
        {
          label: "",
          key: "timeBlock",
          tdClass: "color-b",
          thStyle: "width: 80%;",
        },
      ],
      meetingFields: [
        {
          label: this.$t("common.type"),
          key: "meetingTypeName",
          thClass: "color-b",
        },
        {
          label: this.$t("common.location"),
          key: "location",
          thClass: "color-b",
        },
        {
          label: this.$t("common.date"),
          key: "date",
          thClass: "color-b",
        },
        {
          label: this.$t("common.start_time"),
          key: "startTime",
          thClass: "color-b",
        },
        {
          label: this.$t("common.end_time"),
          key: "endTime",
          thClass: "color-b",
        },
      ],
      contactPersonFields: [
        {
          label: "",
          key: "roleName",
          tdClass: "pl-0",
        },
        {
          label: "",
          key: "name",
        },
      ],
      rankingFields: [
        {
          label: "",
          key: "name",
          tdClass: "pl-0",
        },
      ],
    };
  },
  methods: {
    localizeDate(date) {
      return this.$options.filters.dateLocalized(date, "dd MMMM yyyy - HH:mm");
    },
    getTranslatedDayName(dayName) {
      const weekDaysLabel = {
        Monday: this.$t("weekday.monday"),
        Tuesday: this.$t("weekday.tuesday"),
        Wednesday: this.$t("weekday.wednesday"),
        Thursday: this.$t("weekday.thursday"),
        Friday: this.$t("weekday.friday"),
        Saturday: this.$t("weekday.saturday"),
        Sunday: this.$t("weekday.sunday"),
      };
      return weekDaysLabel[dayName];
    },
  },
};
</script>
