<template>
  <div>
    <b-table
      :fields="tableDetailsFields"
      :items="[]"
      small
      fixed
      style="margin-left: 50px"
    ></b-table>
    <b-table
      v-for="registration in registrationsWithTeams"
      :key="keyCode(registration.organisationName)"
      :fields="tableDetailsFields"
      :items="registration.teams"
      small
      fixed
      responsive
      style="margin-left: 50px"
      thead-class="d-none"
      class="mb-0"
    >
      <template #cell(oarBladeUrl)="row">
        <img
          :src="row.item.oarBladeUrl"
          fluid
          style="max-width: 50px; max-height: 30px"
          class="center-block img-responsive"
        />
      </template>
      <template #cell(rowers)="row">
        <b-row v-if="bow(row) != null && bow(row) != undefined">
          <router-link
            v-if="bow(row)"
            :to="{
              name: 'ResultsPerPerson',
              params: { guid: bow(row).personId },
            }"
          >
            {{ `b: ${bow(row).fullName}` }}
          </router-link>
        </b-row>
        <b-row v-for="rower in rowers(row)" :key="keyCode(rower.personId)">
          <router-link
            :to="{
              name: 'ResultsPerPerson',
              params: { guid: rower.personId },
            }"
          >
            {{
              rower.boatPosition
                ? `${rower.boatPosition} ${rower.fullName}`
                : ""
            }}
          </router-link>
        </b-row>
        <b-row>
          <router-link
            v-if="stroke(row)"
            :to="{
              name: 'ResultsPerPerson',
              params: { guid: stroke(row).personId },
            }"
          >
            {{ `s: ${stroke(row).fullName}` }}
          </router-link>
        </b-row>
        <b-row>
          <router-link
            v-if="cox(row)"
            :to="{
              name: 'ResultsPerPerson',
              params: { guid: cox(row).personId },
            }"
          >
            {{ `st: ${cox(row).fullName}` }}
          </router-link>
        </b-row>
      </template>
      <template #cell(coach)="row">
        <router-link
          v-for="coach in coaches(row)"
          :key="coach.personId"
          :to="{
            name: 'ResultsPerPerson',
            params: { guid: coach.personId },
          }"
        >
          {{
            coaches(row).length > 1
              ? coaches(row).indexOf(coach) == coaches(row).length - 1
                ? `${coach.fullName}`
                : `${coach.fullName}, `
              : coach.fullName
              ? coach.fullName
              : ""
          }}
        </router-link>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    registrations: null,
  },
  data() {
    return {
      tableDetailsFields: [
        {
          label: '',
          key: "oarBladeUrl",
          thClass: "color-b",
          // thStyle: "width:100px;",
        },
        {
          label: this.$t("common.organisation"),
          key: "organisationName",
          thClass: "color-b",
          // thStyle: "width:100px;",
        },
        {
          label: this.$t("common.team"),
          key: "teamFullName",
          thClass: "color-b",
          // thStyle: "width:100px;",
        },
        {
          label: this.$t("common.rower"),
          key: "rowers",
          thClass: "color-b",
          // thStyle: "width:100px;",
        },
        {
          label: this.$t("common.coach"),
          key: "coach",
          thClass: "color-b",
          // thStyle: "width:100px;",
        },
        {
          label: this.$t("common.boat"),
          key: "boatName",
          thClass: "color-b",
          // thStyle: "width:50px;",
        },
      ],
    };
  },
  computed: {
    registrationsWithTeams() {
      if (this.registrations != null) {
        return this.registrations.filter((x) => x.teams.length > 0);
      }
      return [];
    },
  },
  methods: {
    bow(row) {
      return this.teamMembers(row).find((x) => x.isBow);
    },
    rowers(row) {
      return this.teamMembers(row)
        .filter((x) => !x.isBow && !x.isStroke)
        .sort((a, b) => (a.boatPosition > b.boatPosition ? 1 : -1));
    },
    stroke(row) {
      return this.teamMembers(row).find((x) => x.isStroke);
    },
    cox(row) {
      let activeTeamVersion = row.item.teamVersions.find((x) => x.isActive);
      if (activeTeamVersion) {
        return activeTeamVersion.teamMembers.find((tm) => tm.isCox);
      }
      return null;
    },
    teamMembers(row) {
      let activeTeamVersion = row.item.teamVersions.find((x) => x.isActive);
      if (activeTeamVersion) {
        return activeTeamVersion.teamMembers.length
          ? activeTeamVersion.teamMembers
          : [];
      }
      return [];
    },
    coaches(row) {
      let activeTeamVersion = row.item.teamVersions.find((x) => x.isActive);
      if (activeTeamVersion) {
        return activeTeamVersion.teamMembers.filter((tm) => tm.isCoach);
      }
      return [];
    },
  },
};
</script>
