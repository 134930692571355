<template>
  <b-overlay :show="tournamentDaysLoading" rounded="sm">
    <b-tabs class="mt-2 mb-2" v-if="tournamentDays.length">
      <b-tab
        title-link-class="days-nav"
        v-for="tournamentDay in tournamentDays"
        :key="tournamentDay.id"
        @click="fetch(tournamentDay.id, true)"
        lazy
        :active="tournamentDay.id === currentTournamentDayId"
      >
        <template #title>
          <div class="fs-15 text-center">
            {{ $options.filters.dateLocalized(tournamentDay.date, "EEE") }}
          </div>
          <div class="fs-15 text-center">
            {{ formatDate(tournamentDay.date, "d") }}
            {{ $options.filters.dateLocalized(tournamentDay.date, "MMM") }}
          </div>
        </template>
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import { tournamentDaysService } from "@/services";
export default {
  mixins: [tournamentDaysService],
  data() {
    return {
      currentTournamentDayId: Number,
    };
  },
  methods: {
    async loadData() {
      await this.getTournamentDays({
        params: {
          tournamentId: this.tournamentId,
        },
      }).then((result) => {
        if (result.data.length) {
          this.currentTournamentDayId = result.data[0].id;
          this.fetch(result.data[0].id);
        }
      });
    },
    fetch(tournamentDayId, fromNav) {
      this.currentTournamentDayId = tournamentDayId;
      this.$emit("fetchData", this.currentTournamentDayId, fromNav);
    },
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.$eventHub.$on("update-tournament-day", (tournamentDayId) => {
      this.currentTournamentDayId = tournamentDayId;
    });
    this.loadData();
  },
};
</script>
