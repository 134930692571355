import { mapGetters, mapActions } from 'vuex';

export const rankingGroupsService = {
    computed: {
        ...mapGetters([
            'rankingGroups', 'rankingGroupsLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getRankingGroups'
        ])
    }
}