import { mapGetters, mapActions } from 'vuex';

export const raceTeamsService = {
    computed: {
        ...mapGetters([
            'raceTeams', 'raceTeamsLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getRaceTeams',
        ])
    }
}