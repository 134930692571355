import { mapGetters, mapActions } from 'vuex';

export const matchTeamsService = {
    computed: {
        ...mapGetters([
            'matchTeams', 'matchTeamsLoading',
        ])
    },
    methods: {
        ...mapActions([
            'getMatchTeams',
        ])
    }
}