<template>
  <div>
    <tournament-days-card @fetchData="loadData"/> 
    <div class="ml-2 mt-4" v-if="racesLoading">
      <h3>
        {{ $t("common.loading_results") }}
        <div class="spinner-grow spinner-grow-sm mb-1" role="status" />
      </h3>
    </div>
    <b-table
      v-else
      :fields="fields"
      :items="races"
      show-empty
      borderless
      :empty-text="$t('common.no_records')"
      small
    >
      <template v-slot:cell(startTime)="{ item }">
        {{ formatDate(item.startTime, "HH:mm") }}
      </template>
      <template v-slot:cell(matchName)="{ item }">
        {{ item.match.name }}
      </template>
      <template #cell(action)="row">
        <b-link v-if="row.item.raceTeams.length">
          <b-icon-chevron-down
            v-if="!row.detailsShowing"
            class="color-b"
            @click="row.toggleDetails"/>
          <b-icon-chevron-up v-else class="color-b" @click="row.toggleDetails"
        /></b-link>
        <b-form-checkbox
          class="d-none"
          v-model="row.detailsShowing"
          @change="row.toggleDetails"
        >
        </b-form-checkbox>
      </template>
      <template #row-details="row">
        <result-details-card
          :races="races.filter((x) => x.id === row.item.id)"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { racesService } from "@/services";
import { ResultDetailsCard } from "@/app/RaceResults";

export default {
  mixins: [racesService],
  components: { ResultDetailsCard },
  props: {
    raceId: {
      type: Number,
      default: 0,
    },
    tournamentDayId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      displayNone: "d-none",
      fields: [
        {
          label: this.$t("common.start_time"),
          key: "startTime",
          thClass: "color-b",
        },
        {
          label: "#",
          key: "match.number",
          thClass: "color-b",
        },
        {
          label: this.$t("common.match_code"),
          key: "match.code",
          thClass: "color-b",
        },
        {
          label: "",
          key: "match.alternativeName",
          thClass: "color-b",
        },
        {
          label: "",
          key: "match.name",
          thClass: "color-b",
        },
        {
          label: this.$t("common.results"),
          key: "action",
          thClass: "color-b",
        },
        {
          label: this.$t("common.race"),
          key: "name",
          thClass: "color-b",
        },
      ],
    };
  },
  methods: {
    computeMinutes(currentMilestone) {
      let defaultDate = new Date(0);
      let formattedDate = this.formatDate(
        addSeconds(defaultDate, currentMilestone),
        "mm:ss"
      );
      return formattedDate;
    },
    async loadData(tournamentDayId) {
      if (this.raceId !== 0 && this.tournamentDayId !== 0) {
        this.getRaces({
          params: {
            tournamentId: this.$route.params.id,
            raceId: this.raceId,
          },
        }).then((result) => {
          result.data.forEach((race) => {
            this.$set(race, "_showDetails", true);
            race.raceTeams.forEach((raceTeam) => {
              raceTeam.raceTeamTimes.sort(function(a, b) {
                return a.distance - b.distance;
              });
            });
          });
          this.$eventHub.$emit("update-tournament-day", this.tournamentDayId);
        });
      } else {
        this.getRaces({
          params: {
            tournamentdayId: tournamentDayId,
            tournamentId: this.$route.params.id,
          },
        }).then((result) => {
          result.data.forEach((race) => {
            race.raceTeams.forEach((raceTeam) => {
              raceTeam.raceTeamTimes.sort(function(a, b) {
                return a.distance - b.distance;
              });
            });
          });
        });
      }
    },
  },
};
</script>

<style scoped>
tr .body:hover {
  background-color: #d2d3f0;
}
th:nth-child(4),
td:nth-child(4) {
  border-left: 0.5px solid var(--light-blue);
}

td {
  margin-left: 5px;
  padding-left: 30px;
  padding-top: 2px;
  padding-right: 5px;
  padding-bottom: 5px;
}
</style>
