import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import { getShortLocaleCode } from "./i18n";
import { federationIdService } from "./services";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "bootstrap-vue/dist/bootstrap-vue.css";
import SeasonsNavigationCard from "@/app/Seasons/SeasonsNavigationCard";
import TournamentDaysCard from "@/app/shared/TournamentDaysCard";
import { dateTimeService } from "@foys-tech/foys-utils/dateTimeService";
import { keyCodeGeneratorService } from './services';
import { localize } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import nl from "vee-validate/dist/locale/nl.json";
import '@/plugins/dateLocalize.js';

import { ValidationObserver, ValidationProvider } from "vee-validate";

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.component("seasons-navigation", SeasonsNavigationCard);
Vue.component("tournament-days-card", TournamentDaysCard);

import { BaseMultiselect } from '@foys-tech/foys-base-components';
Vue.component("base-multiselect", BaseMultiselect);

Vue.mixin(federationIdService);
Vue.mixin(dateTimeService);
Vue.mixin(keyCodeGeneratorService);

localize({
  en,
  nl
});

let locale = getShortLocaleCode();
localize(locale);

Vue.filter("dash", function (value) {
  return value ? value : '-';
})
Vue.filter("yesNo", function (value) {
  return value ? i18n.t("common.yes") : i18n.t("common.no");
});
Vue.filter("currencyEuro", function (value) {
  if (!value) return `-`;
  if (typeof value !== 'number') {
    return value;
  }
  return `€ ${Number(value)
    .toFixed(2)
    .toString()
    .replace('.', ',')}`;
});

Vue.filter("measurementMeter", function (value) {
  if (!value) return `-`;
  if (typeof value !== 'number') {
    return value;
  }
  return `${Number(value)
    .toString()
    .replace('.', ',')}m`;
});

import * as VueGoogleMaps from 'vue2-google-maps';
Vue.component('google-marker', VueGoogleMaps.Marker);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC3YRishVVSygSBtbgzJiWus1jrikuJJPM',
    libraries: 'places' // This is required if you use the Autocomplete plugin
  },
  installComponents: true
});

Vue.config.productionTip = false

Vue.prototype.$eventHub = new Vue();
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
