<template>
  <b-overlay :show="matchTeamsLoading">
    <p class="font-weight-bold blue fs-14 mt-2 mb-2">
      {{ `${totalRegistrations()} ${$t("common.registrations_lowercase")} ` }}
    </p>
    <h5 class="color-b">{{ organisationName }}</h5>
    <tournament-days-card @fetchData="loadData" />
    <b-table
      :fields="fields"
      :items="matchTeams"
      show-empty
      responsive
      borderless
      :empty-text="$t('common.no_records')"
      small
    >
      <template #cell(stroke)="row">
        <router-link
          v-if="stroke(row)"
          :to="{
            name: 'ResultsPerPerson',
            params: { guid: stroke(row).personId },
          }"
        >
          {{ stroke(row).fullName ? stroke(row).fullName : "" }}
        </router-link>
      </template>
      <template #cell(action)="row">
        <b-link v-if="row.item.matchRegistration">
          <b-icon-chevron-down
            v-if="!row.detailsShowing"
            class="color-b"
            @click="row.toggleDetails"
          />
          <b-icon-chevron-up
            v-else
            class="color-b"
            @click="row.toggleDetails"
          />
        </b-link>
        <b-form-checkbox
          class="d-none"
          v-model="row.detailsShowing"
          @change="row.toggleDetails"
        >
        </b-form-checkbox>
      </template>
      <template #row-details="row">
        <b-table
          class="table-sm"
          :fields="tableDetailsFields"
          :items="row.item.teamVersions.filter((x) => x.isActive)"
          responsive
        >
          <template #cell(organisationName)>
            <span>{{ organisationName }}</span>
          </template>
          <template #cell(cox)="row">
            <router-link
              v-if="cox(row)"
              :to="{
                name: 'ResultsPerPerson',
                params: { guid: cox(row).personId },
              }"
            >
              {{ cox(row).fullName ? cox(row).fullName : "" }}
            </router-link>
          </template>
          <template #cell(rowers)="row">
            <b-row
              v-for="teamMember in row.item.teamMembers.filter(
                (tm) => tm.isBow
              )"
              :key="keyCode(teamMember.personId)"
            >
              <router-link
                :to="{
                  name: 'ResultsPerPerson',
                  params: { guid: teamMember.personId },
                }"
              >
                {{ `b: ${teamMember.fullName}` }}
              </router-link>
            </b-row>
            <b-row
              v-for="teamMember in row.item.teamMembers
                .filter((tm) => !tm.isBow && !tm.isStroke && tm.boatPosition)
                .sort((a, b) => (a.boatPosition > b.boatPosition ? 1 : -1))"
              :key="keyCode(teamMember.personId)"
            >
              <router-link
                :to="{
                  name: 'ResultsPerPerson',
                  params: { guid: teamMember.personId },
                }"
              >
                {{ `${teamMember.boatPosition}: ${teamMember.fullName}` }}
              </router-link>
            </b-row>
            <b-row
              v-for="teamMember in row.item.teamMembers.filter(
                (tm) => tm.isStroke
              )"
              :key="keyCode(teamMember.personId)"
            >
              <router-link
                :to="{
                  name: 'ResultsPerPerson',
                  params: { guid: teamMember.personId },
                }"
              >
                {{ `s: ${teamMember.fullName}` }}
              </router-link>
            </b-row>
            <b-row
              v-for="teamMember in row.item.teamMembers.filter(
                (tm) => tm.isCox
              )"
              :key="keyCode(teamMember.personId)"
            >
              <router-link
                :to="{
                  name: 'ResultsPerPerson',
                  params: { guid: teamMember.personId },
                }"
              >
                {{ `st: ${teamMember.fullName}` }}
              </router-link>
            </b-row>
          </template>
          <template #cell(boat)>
            {{ row.item.boatName }}
          </template>
          <template #cell(coach)="row">
            <router-link
              v-if="coach(row)"
              :to="{
                name: 'ResultsPerPerson',
                params: { guid: coach(row).personId },
              }"
            >
              {{ coach(row).fullName ? coach(row).fullName : "" }}
            </router-link>
          </template>
        </b-table>
      </template>
    </b-table>
  </b-overlay>
</template>

<script>
import { matchTeamsService } from "@/services";

export default {
  mixins: [matchTeamsService],
  props: {
    organisationName: null,
    organisationId: null,
  },
  data() {
    return {
      tableDetailsFields: [
        {
          label: this.$t("common.organisation"),
          key: "organisationName",
          thClass: "color-b",
          thStyle: "width:100px;",
        },
        {
          label: this.$t("common.rower"),
          key: "rowers",
          thClass: "color-b",
          thStyle: "width:100px;",
        },
        {
          label: this.$t("common.cox"),
          key: "cox",
          thClass: "color-b",
          thStyle: "width:100px;",
        },
        {
          label: this.$t("common.coach"),
          key: "coach",
          thClass: "color-b",
          thStyle: "width:100px;",
        },
        {
          label: this.$t("common.boat"),
          key: "boat",
          thClass: "color-b",
          thStyle: "width:50px;",
        },
      ],
      fields: [
        {
          label: "#",
          key: "matchRegistration.match.number",
          tdClass: "p-2",
          thStyle: "width:20px;",
          thClass: "color-b",
        },
        {
          label: this.$t("common.match_code"),
          key: "matchRegistration.match.code",
          tdClass: "p-2",
          thClass: "color-b",
        },
        {
          label: "",
          key: "matchRegistration.match.alternativeName",

          thClass: "color-b",
        },
        {
          label: "",
          key: "matchName",
          tdClass: "p-2",
          thClass: "color-b",
        },
        {
          label: this.$t("common.teams"),
          key: "teamFullName",
          tdClass: "p-2",
          thClass: "color-b",
        },
        {
          label: this.$t("common.stroke"),
          key: "stroke",
          tdClass: "p-2",
          thClass: "color-b",
          thStyle: "min-width: 200px;",
        },
        { label: "", key: "action", tdClass: "p-2", thClass: "color-b" },
      ],
    };
  },
  methods: {
    stroke(row) {
      let stroke = {};
      row.item.teamVersions.forEach((tv) => {
        tv.teamMembers.forEach((tm) => {
          if (tm.isStroke) {
            stroke = tm;
          }
        });
      });
      return stroke;
    },
    cox(row) {
      return row.item.teamMembers.find((tm) => tm.isCox);
    },
    coach(row) {
      return row.item.teamMembers.find((tm) => tm.isCoach);
    },
    totalRegistrations() {
      return this.matchTeams.map((x) => x.matchRegistration).length;
    },
    async loadData(tournamentDayId) {
      this.getMatchTeams({
        params: {
          tournamentId: this.tournamentId,
          tournamentDayId: tournamentDayId,
          organisationId: this.organisationId,
        },
      });
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
};
</script>
