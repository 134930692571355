<template>
  <div>
    <b-tabs align="center">
      <div class="section-split"></div>
      <b-tab
        title-link-class="custom-nav"
        :title="$t('common.per_matrix')"
        lazy
        :active="tab === 'matrix'"
        @click="updateRouting('matrix')"
      >
        <results-by-matrix-card />
      </b-tab>
      <b-tab
        title-link-class="custom-nav"
        :title="$t('common.per_match')"
        lazy
        :active="tab === 'match'"
        @click="updateRouting('match')"
      >
        <results-by-match-card :filter="filter" />
      </b-tab>
      <b-tab
        title-link-class="custom-nav"
        :title="$t('common.per_race')"
        lazy
        :active="tab === 'race'"
        @click="updateRouting('race')"
      >
        <results-by-race-card
          :race-id="raceId"
          :tournament-day-id="tournamentDayId"
        />
      </b-tab>
      <b-tab
        title-link-class="custom-nav"
        @click="
          regenerateComponentKey();
          updateRouting('club');
        "
        :title="$t('common.per_club')"
        lazy
        :active="tab === 'club'"
      >
        <results-by-club-card :key="keyCode(key)" />
      </b-tab>
    </b-tabs>
    <b-row v-if="registrationSystemTypeName" class="mt-2">
      <b-col>
        <div class="text-center">
          <small
            >{{ $t("common.results_from_system") }}
            {{ registrationSystemTypeName }}</small
          >
        </div></b-col
      >
    </b-row>
  </div>
</template>

<script>
import {
  ResultsByRaceCard,
  ResultsByMatchCard,
  ResultsByClubCard,
  ResultsByMatrixCard,
} from "@/app/RaceResults";

export default {
  components: {
    ResultsByRaceCard,
    ResultsByMatchCard,
    ResultsByClubCard,
    ResultsByMatrixCard,
  },
  props: {
    registrationSystemTypeName: {
      type: String,
      default: "",
    },
    subMenu: {
      type: String,
      default: "matrix",
    },
    raceId: {
      type: Number,
      default: 0,
    },
    tournamentDayId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tab: "matrix",
      key: String,
      filter: null,
    };
  },
  created() {
    this.tab = this.subMenu;
    this.$eventHub.$on("results-navigate-to-by-match-tab", (event) => {
      this.filter = event;
      this.tab = "match";
    });
  },
  methods: {
    updateRouting(subMenu) {
      this.$router.replace({
        query: { tab: this.$route.query.tab, subMenu: subMenu },
      });
    },
    regenerateComponentKey(key) {
      this.tab = "matrix";
      this.key = this.keyCode(key).toString();
    },
  },
};
</script>
