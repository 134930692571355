<template>
  <b-row class="mt-1 pt-1 flex-nowrap">
    <b-link @click="navigateTenYearsBackward">
      <b-icon-chevron-double-left />
    </b-link>
    <b-link class="ml-1" @click="navigatePreviousSeason">
      <b-icon-chevron-left />
    </b-link>
    <div
      v-if="seasonsLoading"
      class="spinner-border spinner-border-sm p-2"
      role="status"
    />
    <span v-else class="blue fw-b-600 ml-2 mr-2">{{ seasonRender.name }}</span>
    <b-link @click="navigateNextSeason">
      <b-icon-chevron-right />
    </b-link>
    <b-link class="ml-1" @click="navigateTenYearsForward">
      <b-icon-chevron-double-right />
    </b-link>
  </b-row>
</template>

<script>
import { seasonsService, federationIdService } from "@/services";

export default {
  mixins: [seasonsService, federationIdService],
  data() {
    return {
      seasonRender: {},
    };
  },
  methods: {
    async loadData() {
      this.getSeasons({
        params: {
          federationId: this.federationId(),
        },
      }).then((response) => {
        this.seasonRender = response.data
          ? response.data.items.find((x) => x.isActive)
          : {};

        this.$emit("success", { seasonId: this.seasonRender.id });
      });
    },
    navigateTenYearsBackward() {
      if (
        this.seasons.items.find(
          (x) => x.position === this.seasonRender.position - 10
        )
      ) {
        this.seasonRender = this.seasons.items.find(
          (x) => x.position === this.seasonRender.position - 10
        );
      } else {
        let lastSeasonByOrder = this.seasons.items.sort((a, b) =>
          a.position > b.position ? 1 : -1
        );

        this.seasonRender = Object.assign(lastSeasonByOrder[0]);
      }

      this.$emit("success", { seasonId: this.seasonRender.id });
    },
    navigateTenYearsForward() {
      if (
        this.seasons.items.find(
          (x) => x.position === this.seasonRender.position + 10
        )
      ) {
        this.seasonRender = this.seasons.items.find(
          (x) => x.position === this.seasonRender.position + 10
        );
      } else {
        let lastSeasonByOrder = this.seasons.items.sort((a, b) =>
          a.position > b.position ? 1 : -1
        );

        this.seasonRender = Object.assign(
          lastSeasonByOrder[lastSeasonByOrder.length - 1]
        );
      }

      this.$emit("success", { seasonId: this.seasonRender.id });
    },
    navigatePreviousSeason() {
      if (
        this.seasons.items.find(
          (x) => x.position === this.seasonRender.position - 1
        )
      ) {
        this.seasonRender = this.seasons.items.find(
          (x) => x.position === this.seasonRender.position - 1
        );

        this.$emit("success", { seasonId: this.seasonRender.id });
      }
    },
    navigateNextSeason() {
      if (
        this.seasons.items.find(
          (x) => x.position === this.seasonRender.position + 1
        )
      ) {
        this.seasonRender = this.seasons.items.find(
          (x) => x.position === this.seasonRender.position + 1
        );

        this.$emit("success", { seasonId: this.seasonRender.id });
      }
    },
  },
  created() {
    this.loadData();
  },
};
</script>
