<template>
  <div v-if="clubRacesView">
    <div class="ml-2 mt-4" v-if="organisationsLoading">
      <h3>
        {{ $t("common.loading_organisations") }}
        <div class="spinner-grow spinner-grow-sm mb-1" role="status" />
      </h3>
    </div>
    <b-table
      v-else
      :fields="fields"
      :items="organisations"
      show-empty
      responsive
      borderless
      :empty-text="$t('common.no_records')"
      small
    >
      <template #cell(organisationName)="row">
        <b-link @click="navigateToClubDetailsCard(row.item)">{{
          row.item.organisationName
        }}</b-link>
      </template>
    </b-table>
  </div>
  <div v-else>
    <results-by-club-details-card
      :organisationName="clubName"
      :organisationId="organisationId"
    >
    </results-by-club-details-card>
  </div>
</template>

<script>
import { organisationsService } from "@/services";
import { ResultsByClubDetailsCard } from "@/app/RaceResults";

export default {
  mixins: [organisationsService],
  components: { ResultsByClubDetailsCard },
  data() {
    return {
      clubName: "",
      clubRacesView: true,
      fields: [
        {
          label: this.$t("common.name"),
          key: "organisationName",
          thClass: "color-b",
        },
        {
          label: this.$t("common.races"),
          key: "count",
          thClass: "color-b",
        },
      ],
    };
  },
  methods: {
    navigateToClubDetailsCard(item) {
      this.clubName = item.organisationName;
      this.organisationId = item.organisationId;
      this.clubRacesView = false;
    },
    async loadData() {
      this.getOrganisations({
        params: {
          tournamentId: this.tournamentId,
          onlyResults: true,
        },
      });
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
};
</script>
