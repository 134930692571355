var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{staticStyle:{"margin-left":"50px"},attrs:{"fields":_vm.tableDetailsFields,"items":[],"small":"","fixed":""}}),_vm._l((_vm.registrationsWithTeams),function(registration){return _c('b-table',{key:_vm.keyCode(registration.organisationName),staticClass:"mb-0",staticStyle:{"margin-left":"50px"},attrs:{"fields":_vm.tableDetailsFields,"items":registration.teams,"small":"","fixed":"","responsive":"","thead-class":"d-none"},scopedSlots:_vm._u([{key:"cell(oarBladeUrl)",fn:function(row){return [_c('img',{staticClass:"center-block img-responsive",staticStyle:{"max-width":"50px","max-height":"30px"},attrs:{"src":row.item.oarBladeUrl,"fluid":""}})]}},{key:"cell(rowers)",fn:function(row){return [(_vm.bow(row) != null && _vm.bow(row) != undefined)?_c('b-row',[(_vm.bow(row))?_c('router-link',{attrs:{"to":{
            name: 'ResultsPerPerson',
            params: { guid: _vm.bow(row).personId },
          }}},[_vm._v(" "+_vm._s(("b: " + (_vm.bow(row).fullName)))+" ")]):_vm._e()],1):_vm._e(),_vm._l((_vm.rowers(row)),function(rower){return _c('b-row',{key:_vm.keyCode(rower.personId)},[_c('router-link',{attrs:{"to":{
            name: 'ResultsPerPerson',
            params: { guid: rower.personId },
          }}},[_vm._v(" "+_vm._s(rower.boatPosition ? ((rower.boatPosition) + " " + (rower.fullName)) : "")+" ")])],1)}),_c('b-row',[(_vm.stroke(row))?_c('router-link',{attrs:{"to":{
            name: 'ResultsPerPerson',
            params: { guid: _vm.stroke(row).personId },
          }}},[_vm._v(" "+_vm._s(("s: " + (_vm.stroke(row).fullName)))+" ")]):_vm._e()],1),_c('b-row',[(_vm.cox(row))?_c('router-link',{attrs:{"to":{
            name: 'ResultsPerPerson',
            params: { guid: _vm.cox(row).personId },
          }}},[_vm._v(" "+_vm._s(("st: " + (_vm.cox(row).fullName)))+" ")]):_vm._e()],1)]}},{key:"cell(coach)",fn:function(row){return _vm._l((_vm.coaches(row)),function(coach){return _c('router-link',{key:coach.personId,attrs:{"to":{
          name: 'ResultsPerPerson',
          params: { guid: coach.personId },
        }}},[_vm._v(" "+_vm._s(_vm.coaches(row).length > 1 ? _vm.coaches(row).indexOf(coach) == _vm.coaches(row).length - 1 ? ("" + (coach.fullName)) : ((coach.fullName) + ", ") : coach.fullName ? coach.fullName : "")+" ")])})}}],null,true)})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }