import Vapi from "vuex-rest-api";
import axios from "axios";
import _ from "lodash";

export default class BaseStoreBuilder {
  constructor(options) {
    Object.assign(this, options);

    this.resourceName =
      this.resourceName ||
      this.entityName
        .split(/(?=[A-Z])/)
        .map((s) => s.toLowerCase())
        .join("-") + "s";

    if (this.scopedTo) {
      this.scopedToId = `${this.scopedTo}Id`;
      this.isScoped = true;
    }

    this.baseURL = this.isScoped
      ? `${this.baseURL}/${_.kebabCase(this.scopedTo)}s`
      : this.baseURL;

    this.state = {};
    this.getters = {};
    this.actions = [];
  }

  /* Methods to build base crud operations based on builder options. */
  getEntitiesList() {
    let me = this;

    let listPropertyName = `${this.entityName}s`;

    if (this.entityName.endsWith("y") && !this.entityName.toLowerCase().includes("day")) {
      listPropertyName = `${this.entityName.substring(
        0,
        this.entityName.length - 1
      )}ies`;
    }
    this.state[listPropertyName] = [];

    this.get(
      {
        path: function(params) {
          return me.isScoped
            ? `${params[me.scopedToId]}/${me.resourceName}`
            : `${me.resourceName}`;
        },
        queryParams: true,
      },
      listPropertyName
    );

    return this;
  }
  getEntityById() {
    let me = this;

    this.get(
      {
        path: function(params) {
          return me.isScoped
            ? `${params[me.scopedToId]}/${me.resourceName}/${params.id}`
            : `${me.resourceName}/${params.id}`;
        },
        queryParams: true,
      },
      this.entityName,
      {}
    );

    return this;
  }
  postEntity() {
    let me = this;

    this.post(
      {
        path: function(params) {
          return me.isScoped
            ? `${params[me.scopedToId]}/${me.resourceName}`
            : `${me.resourceName}`;
        },
      },
      this.entityName
    );

    return this;
  }
  putEntity() {
    let me = this;

    this.put(
      {
        path: function(params) {
          return me.isScoped
            ? `${params[me.scopedToId]}/${me.resourceName}/${params.id}`
            : `${me.resourceName}/${params.id}`;
        },
      },
      this.entityName
    );

    return this;
  }
  deleteEntity() {
    let me = this;

    this.delete(
      {
        path: function(params) {
          return me.isScoped
            ? `${params[me.scopedToId]}/${me.resourceName}/${params.id}`
            : `${me.resourceName}/${params.id}`;
        },
      },
      this.entityName
    );

    return this;
  }

  /* Base methods to build crud operations based on input options. */
  get(options, property, defaultStoreValue) {
    if (!this.state.hasOwnProperty(property)) {
      this.state[property] = defaultStoreValue || null;
    }

    return this.addAction("get", options, property).addGetGetter(property);
  }
  post(options, property) {
    options.property = `${property}Save`;
    options.action = `add${this.capitalizeString(property)}`;

    if (!this.state.hasOwnProperty(options.property)) {
      this.state[options.property] = null;
    }

    return this.addAction("post", options).addSaveGetter(property);
  }
  put(options, property) {
    options.property = `${property}Save`;
    options.action = `update${this.capitalizeString(property)}`;

    if (!this.state.hasOwnProperty(options.property)) {
      this.state[options.property] = null;
    }

    return this.addAction("put", options).addSaveGetter(property);
  }
  delete(options, property) {
    options.property = `${property}Delete`;
    options.action = `delete${this.capitalizeString(property)}`;

    if (!this.state.hasOwnProperty(options.property)) {
      this.state[options.property] = null;
    }

    return this.addAction("delete", options).addDeleteGetter(property);
  }

  /* Methods to add 4 base crud operations at once */
  crud() {
    return this.getEntitiesList()
      .postEntity()
      .putEntity()
      .deleteEntity();
  }

  /* Build vapi and store. */
  build() {
    let vapi = new Vapi({
      baseURL: this.baseURL,
      state: this.state,
    });

    this.actions.forEach((action) => {
      vapi[action.method](action.options);
    });

    let store = vapi.getStore({
      createStateFn: true,
    });
    store.getters = this.getters;
    return store;
  }

  /* Helper internal methods. Try to avoid using these methods. Instead use methods above. */
  addAction(method, options, property) {
    if (!options.action) {
      options.action = `${method}${this.capitalizeString(property)}`;
    }
    if (!options.property) {
      options.property = property;
    }

    if (options.cancellable) {
      options.beforeRequest = (state) => {
        if (options.requestConfig && options.request) {
          options.isCancelled = true;
          options.request.cancel();
          options.request = null;
          options.requestConfig = null;
        }

        options.request = axios.CancelToken.source();
        options.requestConfig = {
          cancelToken: options.request.token,
        };
      };
      options.onError = (state, error, axios, { params, data }) => {
        if (options.isCancelled) {
          state[property] = [];
          state.pending[options.property] = true;
          state.error[options.property] = null;
          options.isCancelled = false;
        }
      };
    }

    this.actions.push({ method, options });
    return this;
  }
  addGetGetter(property) {
    if (!property) {
      return this;
    }
    this.getters[property] = function(state) {
      return state[property];
    };

    this.getters[`${property}Loading`] = function(state) {
      return state.pending[property];
    };

    return this;
  }
  addSaveGetter(property) {
    let savePropertyName = `${property}Save`;
    if (!this.getters[`${property}Saving`]) {
      this.getters[`${property}Saving`] = function(state) {
        return state.pending[savePropertyName];
      };
    }

    if (!this.getters[`${savePropertyName}Error`]) {
      this.getters[`${savePropertyName}Error`] = function(state) {
        return state.error[savePropertyName];
      };
    }
    return this;
  }
  addDeleteGetter(property) {
    let deletePropertyName = `${property}Delete`;
    if (!this.getters[`${property}Deleting`]) {
      this.getters[`${property}Deleting`] = function(state) {
        return state.pending[deletePropertyName];
      };
    }

    if (!this.getters[`${deletePropertyName}Error`]) {
      this.getters[`${deletePropertyName}Error`] = function(state) {
        return state.error[deletePropertyName];
      };
    }
    return this;
  }

  capitalizeString(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
