<template>
  <div>
    <div v-for="race in races" :key="keyCode(race.id)">
      <b-row>
        <b-col cols="2"
          ><h5 class="color-b">
            {{ formatDate(race.startTime, "HH:mm") }}
          </h5></b-col
        >
        <b-col cols="10"
          ><h5 class="color-b">{{ race.name }}</h5></b-col
        >
      </b-row>
      <b-row>
        <p class="font-italic ml-3">{{ race.environmentInformation }}</p>
      </b-row>
      <b-table-simple class="table-sm" responsive>
        <b-tr>
          <b-th style="width: 2%"></b-th>
          <b-th style="width: 5%"></b-th>
          <b-th style="width: 10%; opacity: 50%">
            {{ $t("common.organisation") }}
          </b-th>
          <b-th style="width: 10%; opacity: 50%">
            {{ $t("common.team") }}
          </b-th>
          <b-th style="width: 5%; opacity: 50%">{{ $t("common.lane") }} </b-th>
          <b-th style="width: 30%"></b-th>
          <b-th
            style="opacity: 50%; width: 10%"
            v-for="raceTeamTime in getLongestListOfTimes(
              race.raceTeams.map((x) => x.raceTeamTimes)
            )"
            :key="keyCode(raceTeamTime.id)"
          >
            <template v-if="!raceTeamTime.isFinishTime">{{
              raceTeamTime.distance | measurementMeter
            }}</template>
            <template v-else>{{ $t("common.finish") }}</template>
          </b-th>
          <b-th
            v-if="
              race.raceTeams.some((x) => x.bonusTime != null && x.bonusTime > 0)
            "
            style="width: 30%; opacity: 50%"
            >{{ $t("common.correctedTime") }}</b-th
          >
        </b-tr>
        <b-tr
          class="body"
          v-for="raceTeam in race.raceTeams"
          :key="keyCode(raceTeam.id)"
          style="cursor: pointer"
          @click="
            raceTeam._showDetails
              ? $set(raceTeam, '_showDetails', false)
              : $set(raceTeam, '_showDetails', true)
          "
        >
          <b-td class="pl-0 pt-2">
            <span v-if="raceTeam.didNotFinish" class="font-italic fs-12">
              DNF
            </span>
            <span v-else-if="raceTeam.didNotStart" class="font-italic fs-12">
              DNS
            </span>
            <span v-else>{{ raceTeam.endPosition }}</span></b-td
          >
          <b-td class="pl-4 pt-2">
            <img
              v-if="raceTeam.oarBladeUrl"
              :src="raceTeam.oarBladeUrl"
              fluid
              style="max-width: 50px; max-height: 30px"
              class="center-block img-responsive"
          /></b-td>
          <b-td>
            <b-row>{{ raceTeam.teamVersion.matchTeam.organisationName }}</b-row>
          </b-td>
          <b-td>
            <b-row>{{ raceTeam.teamVersion.matchTeam.name }}</b-row>
            <b-row v-if="raceTeam.teamVersion != null">
              <div
                v-for="teamMember in raceTeam.teamVersion.teamMembers"
                :key="keyCode(teamMember.id)"
              >
                <router-link
                  :to="{
                    name: 'ResultsPerPerson',
                    params: { guid: teamMember.person.personId },
                  }"
                >
                  {{ teamMember.isStroke ? teamMember.fullName : "" }}
                </router-link>
              </div>
            </b-row>
          </b-td>
          <b-td class="pl-3">{{ raceTeam.lane }}</b-td>
          <b-td class="pl-4">
            <div v-if="raceTeam._showDetails">
              <b-row v-if="raceTeam.teamVersion != null">
                <b-col>
                  <b-row>
                    <router-link
                      v-if="bow(raceTeam.teamVersion.teamMembers)"
                      :to="{
                        name: 'ResultsPerPerson',
                        params: {
                          guid: bow(raceTeam.teamVersion.teamMembers).person
                            .personId,
                        },
                      }"
                    >
                      {{
                        `b: ${bow(raceTeam.teamVersion.teamMembers).fullName}`
                      }}
                    </router-link>
                  </b-row>
                  <b-row
                    v-for="rower in rowers(raceTeam.teamVersion.teamMembers)"
                    :key="keyCode(rower.person.personId)"
                  >
                    <router-link
                      :to="{
                        name: 'ResultsPerPerson',
                        params: { guid: rower.person.personId },
                      }"
                    >
                      {{
                        rower.boatPosition
                          ? `${rower.boatPosition} ${rower.fullName}`
                          : ""
                      }}
                    </router-link>
                  </b-row>
                  <b-row>
                    <router-link
                      v-if="stroke(raceTeam.teamVersion.teamMembers)"
                      :to="{
                        name: 'ResultsPerPerson',
                        params: {
                          guid: stroke(raceTeam.teamVersion.teamMembers).person
                            .personId,
                        },
                      }"
                    >
                      {{
                        `s: ${
                          stroke(raceTeam.teamVersion.teamMembers).person
                            .fullName
                        }`
                      }}
                    </router-link>
                  </b-row>
                  <b-row>
                    <router-link
                      v-if="cox(raceTeam.teamVersion.teamMembers) != null"
                      :to="{
                        name: 'ResultsPerPerson',
                        params: {
                          guid: cox(raceTeam.teamVersion.teamMembers).person
                            .personId,
                        },
                      }"
                    >
                      {{
                        `st: ${cox(raceTeam.teamVersion.teamMembers).fullName}`
                      }}
                    </router-link>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row
                    v-for="rower in rowers(raceTeam.teamVersion.teamMembers)"
                    :key="keyCode(rower.person.personId)"
                  >
                    <router-link
                      v-if="rower.isCoach"
                      :to="{
                        name: 'ResultsPerPerson',
                        params: {
                          guid: rower.person.personId,
                        },
                      }"
                    >
                      {{ `coach: ${rower.fullName}` }}
                    </router-link>
                  </b-row>
                  <b-row>
                    {{ raceTeam.boatName ? `boat: ${raceTeam.boatName}` : "" }}
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-td>
          <b-td
            class="pl-3"
            v-for="(raceTeamTime, index) in formatRaceTeamTimes(race, raceTeam)"
            :key="keyCode(raceTeamTime.id)"
          >
            <b-row>{{ computeTime(raceTeamTime.time) }}</b-row>
            <b-row class="ml-10 fs-10">{{
              index !== 0 ? computeTime(raceTeamTime.splitTime) : ""
            }}</b-row>
          </b-td>
          <b-td class="pl-3" v-if="raceTeam.bonusTime > 0">
            <b-row>{{ computeTime(raceTeam.correctedEndTime) }}</b-row>
            <b-row class="ml-10 fs-10"
              >+{{ computeTime(raceTeam.bonusTime) }}</b-row
            >
          </b-td>
        </b-tr>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import { addSeconds } from "date-fns";

export default {
  props: {
    races: null,
  },
  methods: {
    getLongestListOfTimes(raceTeamTimes) {
      var longest = 0;
      var longestRaceTeamTimes = [];
      raceTeamTimes.forEach(function(e) {
        if (e.length > longest) {
          longestRaceTeamTimes = [e];
          longest = e.length;
        } else if (e.length == longest) {
          longestRaceTeamTimes.push(e);
        }
      });

      return longestRaceTeamTimes[0];
    },
    formatRaceTeamTimes(race, raceTeam) {
      var longestRaceTeamTimesInRace = this.getLongestListOfTimes(
        race.raceTeams.map((x) => x.raceTeamTimes)
      );
      if (longestRaceTeamTimesInRace.length > raceTeam.raceTeamTimes.length) {
        var longestDistances = longestRaceTeamTimesInRace.map(
          (e) => e.distance
        );
        var distances = raceTeam.raceTeamTimes.map((e) => e.distance);

        var missingTimes = longestDistances.filter(
          (e) => !distances.includes(e)
        );

        missingTimes.forEach((e) => {
          var obj = {
            id: 0,
            distance: e,
            isFinishTime: false,
            position: 5,
            splitTime: "-",
            time: "-",
          };

          var indexToInsert = null;
          raceTeam.raceTeamTimes.forEach((e, i) => {
            if (obj.distance < e.distance && indexToInsert == null) {
              indexToInsert = i;
            }
          });

          raceTeam.raceTeamTimes.splice(indexToInsert, 0, obj);
        });
      }
      return raceTeam.raceTeamTimes;
    },
    expandRow(raceTeam) {
      if (raceTeam._showDetails) {
      } else {
        $set(raceTeam, "_showDetails", true);
      }
    },
    bow(teamMembers) {
      return teamMembers.find((x) => x.isBow);
    },
    rowers(teamMembers) {
      return teamMembers
        .filter((x) => !x.isBow && !x.isStroke)
        .sort((a, b) => (a.boatPosition > b.boatPosition ? 1 : -1));
    },
    stroke(teamMembers) {
      return teamMembers.find((x) => x.isStroke);
    },
    cox(teamMembers) {
      return teamMembers.find((x) => x.isCox);
    },
    coach(teamMembers) {
      let coach = teamMembers.find((x) => x.isCoach);
      return coach;
    },
    computeTime(currentMilestone) {
      var minutes = Math.floor(currentMilestone / 60);
      var seconds = currentMilestone - minutes * 60;
      return `${minutes}:${
        seconds.toFixed(2) < 10 ? `0${seconds.toFixed(2)}` : seconds.toFixed(2)
      }`;
    },
  },
};
</script>

<style scoped>
td:nth-child(5) {
  border-left: 0.5px solid var(--light-blue);
}

table:last-child {
  border-bottom: 1px solid var(--light-blue);
}

td {
  margin-left: 5px;
  padding-left: 30px;
  padding-top: 2px;
  padding-right: 5px;
  padding-bottom: 5px;
}

tr .body:hover {
  background-color: #d2d3f0;
}
</style>
