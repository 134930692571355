import { mapGetters, mapActions } from 'vuex';

export const matchesService = {
    computed: {
        ...mapGetters([
            'matches', 'matchesLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getMatches'
        ])
    }
}