<template>
  <b-container fluid >
    <div class="person-info d-flex justify-content-center">
      <div class="mt-4">
        <span class="mr-auto"
          ><h3 class="font-weight-bold color-b">{{ data.fullName }}</h3></span
        >
        <span
          ><h5>{{ data.organisationName }}</h5></span
        >
        <span
          ><h5>{{ data.yearOfBirth }}</h5></span
        >
      </div>
    </div>
  </b-container>
</template>

<style scoped>
.person-info {
  border-right: 1px solid var(--light-blue);
}
</style>

<script>
export default {
  props: {
    data: {},
  },
};
</script>
