import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const matchTeams = new BaseStoreBuilder({
    baseURL: appSettings.BaseUrl,
    entityName: "matchTeam"
})
    .getEntitiesList()
    .build();

export default matchTeams;