import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const tournaments = new BaseStoreBuilder({
  baseURL: appSettings.BaseUrl,
  entityName: "tournament"
})
  .getEntityById()
  .getEntitiesList()
  .build();

export default tournaments;