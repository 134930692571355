import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const seasons = new BaseStoreBuilder({
  baseURL: appSettings.BaseUrl,
  entityName: "season"
})
  .getEntitiesList()
  .build();

export default seasons;