export const tournamentRegistrationStatusEnum = {
  data: function() {
    return {
      tournamentRegistrationStatuses: {
        RegistrationOpensOn: "RegistrationOpensOn",
        RegistrationOpenUntil: "RegistrationOpenUntil",
        ChangesPossibleUntil: "ChangesPossibleUntil",
        RegistrationClosed: "RegistrationClosed",
        ResultsAvailable: "ResultsAvailable",
      },
      tournamentRegistrationStatusLabels: {
        RegistrationOpensOn: this.$t("common.registration_opens_on"),
        RegistrationOpenUntil: this.$t("common.registration_open_until"),
        ChangesPossibleUntil: this.$t("common.changes_possible_until"),
        RegistrationClosed: this.$t("common.registration_closed"),
        ResultsAvailable: this.$t("common.results_available"),
      },
    };
  },
  methods: {
    getTournamentRegistrationStatusTitle(tournamentRegistrationStatus) {
      return this.tournamentRegistrationStatusKey(tournamentRegistrationStatus);
    },
    tournamentRegistrationStatusKey(tournamentRegistrationStatus) {
      return Object.values(this.tournamentRegistrationStatusLabels)[
        tournamentRegistrationStatus
      ];
    },
  },
};

export const RankingProgressEnum = {
	data() {
		return {
			RankingProgresses: {
				Advanced: 'Advanced',
				Degraded: 'Degraded',
				NoChange: 'NoChange',
			},
		}
	},
}

export const RankingStrategyEnum = {
	data() {
		return {
			RankingStrategy: {
				SixBestResults: 'SixBestResults',
				TotalMinusWorst: 'TotalMinusWorst',
			},
			RankingStrategyLabel: {
				SixBestResults: this.$t('rankingStrategy.SixBestResults'),
				TotalMinusWorst: this.$t('rankingStrategy.TotalMinusWorst'),
			},
		}
	},
	computed: {
		RankingStrategyList() {
			return enumUtils.getOptions(this.RankingStrategyLabel)
		},
	},
}