import { render, staticRenderFns } from "./ResultsByClubDetailsCard.vue?vue&type=template&id=6f18bf6f&scoped=true&"
import script from "./ResultsByClubDetailsCard.vue?vue&type=script&lang=js&"
export * from "./ResultsByClubDetailsCard.vue?vue&type=script&lang=js&"
import style0 from "./ResultsByClubDetailsCard.vue?vue&type=style&index=0&id=6f18bf6f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f18bf6f",
  null
  
)

export default component.exports