import { mapGetters, mapActions } from 'vuex';

export const tournamentTypesService = {
    computed: {
        ...mapGetters([
            'tournamentTypes', 'tournamentTypesLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getTournamentTypes'
        ])
    }
}