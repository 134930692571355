import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const rankings = new BaseStoreBuilder({
  baseURL: appSettings.BaseUrl,
  entityName: "ranking"
})
  .getEntitiesList()
  .build();

export default rankings;