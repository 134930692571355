import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const tournamentDays = new BaseStoreBuilder({
  baseURL: appSettings.BaseUrl,
  entityName: "tournamentDay",
  resourceName: "tournament-days"
})
  .getEntitiesList()
  .build();

export default tournamentDays;