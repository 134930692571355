import { mapGetters, mapActions } from 'vuex';

export const personService = {
    computed: {
        ...mapGetters([
            'person', 'personLoading',
            'searchPersons', 'searchPersonsLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getPerson',
            'getSearchPersons'
        ])
    }
}