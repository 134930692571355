var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-1"},[_c('tournament-days-card',{on:{"fetchData":_vm.loadData}}),(_vm.matchesLoading)?_c('div',{staticClass:"ml-2 mt-4"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("common.loading_matches"))+" "),_c('div',{staticClass:"spinner-grow spinner-grow-sm mb-1",attrs:{"role":"status"}})])]):_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.matches,"show-empty":"","borderless":"","sort-by":"position","empty-text":_vm.$t('common.no_records'),"small":"","responsive":""},scopedSlots:_vm._u([{key:"cell(matchCodeWithAddition)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.alternativeCode != null ? ((item.alternativeCode) + " " + (item.codeAddition != null ? item.codeAddition : "")) : item.matchCodeWithAddition)+" ")]}},{key:"cell(matchNameWithAddition)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.alternativeName != null ? ((item.alternativeName) + " " + (item.nameAddition != null ? item.nameAddition : "")) : item.matchNameWithAddition)+" ")]}},{key:"cell(tournamentTimeBlockName)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dash")(item.tournamentTimeBlockName))+" ")]}},{key:"cell(isClassifing)",fn:function(ref){
var item = ref.item;
return [(item.isClassifing)?_c('div',[_vm._v(" "+_vm._s(_vm._f("yesNo")(item.isClassifing))+" ")]):_vm._e()]}},{key:"cell(tournamentCostGroupPrice)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyEuro")(item.tournamentCostGroupPrice))+" ")]}},{key:"cell(allowIndividualRegistration)",fn:function(ref){
var item = ref.item;
return [(item.allowIndividualRegistration)?_c('div',[_c('b-button',{attrs:{"variant":"outline-success","size":"sm"},on:{"click":function($event){return _vm.navigateMyEnvironment(item.id)}}},[_vm._v(_vm._s(_vm.$t("common.individual_registration"))+" "),_c('b-icon-arrow-right',{staticClass:"mr-1"})],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }