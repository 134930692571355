import { mapGetters, mapActions } from 'vuex';

export const seasonsService = {
    computed: {
        ...mapGetters([
            'seasons', 'seasonsLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getSeasons'
        ])
    }
}