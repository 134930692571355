<template>
  <div class="overflow-auto mt-5">
    <h4 class="wp-block-heading active">{{ rankingGroup.name }}</h4>

    <table class="mb-4">
      <tbody>
        <tr>
          <td v-for="field in tableFields" :key="field.key">
            {{ field.label }}
          </td>
          <td><strong>TT</strong></td>
          <td><strong>{{ RankingStrategyLabel[ranking.strategy] }}</strong></td>
        </tr>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.position }}</td>
          <td v-if="item.progress == RankingProgresses.Advanced">
            <b-icon icon="chevron-double-up"></b-icon>
          </td>
          <td v-else-if="item.progress == RankingProgresses.Degraded">
            <b-icon icon="chevron-double-down"></b-icon>
          </td>
          <td v-else>
            <b-icon icon="dash"></b-icon>
          </td>
          <td>{{ item.name }}</td>
          <td v-for="point in item.rankingPoints" :key="point.id">
            <div v-if="point.dns">
              DNS
            </div>
            <div v-else-if="point.dnf">
              DNF
            </div>
            <div v-else-if="point.amount != null">
              {{ point.amount }}
            </div>
            <div v-else>-</div>
          </td>
          <td>
            <strong>{{ item.total }}</strong>
          </td>
          <td>
            <strong>{{ item.strategyPoints }}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { rankingTournamentsService } from "@/services";
import { RankingProgressEnum, RankingStrategyEnum } from "@/app/shared";

export default {
  mixins: [rankingTournamentsService, RankingProgressEnum, RankingStrategyEnum],
  props: {
    rankingGroup: {
      type: Object,
      default: (e) => {},
    },
    ranking: {
      type: Object,
      default: (e) => {},
    }
  },
  data() {
    return {
      editedItem: {},
    };
  },
  computed: {
    tableFields() {
      var fields = [
        {
          label: "",
        },
        {
          label: "",
        },
        {
          label: "Ploegen",
        },
      ];

      let tournaments = [];

      if (this.rankingTournament) {
        tournaments = this.rankingTournament.map((e) => ({
          key: `${e.id}`,
          label: e.code,
        }));
      }
      return [...fields, ...tournaments];

    },
    items() {
      return this.rankingGroup.teams.map((team) => {
        var points = this.rankingTournament.map((tournament) => {
          var point = team.rankingTeamPoints.find(
            (e) => e.tournamentId == tournament.id
          );

          var obj = { id: Math.random() };
          if (point) {
            obj.amount = point.amount;
            obj.dns = point.dns;
            obj.dnf = point.dnf;
          } else {
            obj.amount = "";
          }
          return obj;
        });

        return {
          id: team.id,
          name: team.name,
          position: team.position,
          total: team.totalPoints,
          progress: team.progress,
          rankingPoints: points,
          strategyPoints: team.strategyPoints
        };
      });
    },
  },
};
</script>

<style scoped>
tbody tr:nth-child(odd) {
  background-color: #f0f0f0;
}
</style>
