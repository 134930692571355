<template>
  <b-container fluid>
    <h5 class="color-b">{{ $t("common.achieved_points") }}</h5>
    <b-row class="mt-3">
      <b-col>
        <div class="d-flex">
          <div class="font-weight-bold color-b pt-2">
            {{ $t("common.scull") }}:
          </div>
          <div class="dot ml-3">{{ data.scull }}</div>
        </div>
      </b-col>
      <b-col>
        <div class="d-flex">
          <div class="font-weight-bold color-b pt-2">
            {{ $t("common.sweeping") }}:
          </div>
          <div class="dot ml-3">{{ data.boord }}</div>
        </div>
      </b-col>
    </b-row>
    <b-table
      class="mt-3"
      borderless
      :fields="fields"
      :items="data.rowingPoints"
      show-empty
      :empty-text="$t('common.no_records')"
      responsive
    >
      <template v-slot:cell(date)="{ item }">
        {{ formatDate(item.date, "dd-MM-yyyy") }}
      </template>
      <template v-slot:cell(type)="{ item }">
        <span v-if="item.type == 'Sweeping'">{{ $t("common.sweeping") }}</span>
        <span v-else>{{ $t("common.scull") }}</span>
      </template>
    </b-table>
  </b-container>
</template>

<script>
export default {
  props: {
    data: {},
  },
  data() {
    return {
      fields: [
        {
          label: this.$t("common.date"),
          key: "date",
          thClass: "color-b p-0",
          tdClass: "p-0",
          thStyle: "width:150px;",
        },
        {
          label: this.$t("common.type"),
          key: "type",
          thClass: "color-b p-0",
          tdClass: "p-0",
          thStyle: "width:100px;",
        },
        {
          label: this.$t("common.points"),
          key: "point",
          thClass: "color-b p-0",
          tdClass: "p-0",
          thStyle: "width:100px;",
        },
        {
          label: this.$t("common.description"),
          key: "description",
          thClass: "color-b p-0",
          tdClass: "p-0",
        },
      ],
    };
  },
};
</script>

<style scoped>
.dot {
  font-size: 1.5rem;
  min-width: 40px;
  min-height: 40px;
  height: auto;
  width: auto;
  background-color: var(--light-blue);
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}
</style>
