import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const matches = new BaseStoreBuilder({
  baseURL: appSettings.BaseUrl,
  entityName: "matche"
})
  .getEntitiesList()
  .build();

export default matches;