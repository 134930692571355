<template>
  <b-row class="ml-0 mr-0">
    <b-col class="p-0" cols="12" md="3">
      <aside
        style="height: 100%; padding-top: 240px;"
        class="d-none d-md-block"
      >
        <b-col style="padding-left: 0px;">
          <h3 class="line trigger" data-target="aside">
            {{ $t("rankings.title") }}
          </h3>
          <ul>
            <li>
              <a class="active">{{ $t("rankings.title") }}</a>
              <ul>
                <li
                  v-for="ranking in rankings.items.filter((e) => e.isOnline)"
                  :key="ranking.id"
                >
                  <a
                    :class="rankingName == ranking.name ? 'active' : ''"
                    :href="`/rankings/${ranking.id}`"
                    >{{ ranking.name }}</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </b-col>
      </aside>
    </b-col>
    <b-col class="p-0">
      <div class="page-header" style="margin-top: 80px">
        <img
          loading="lazy"
          src="https://storage.knrb.nl/2022/01/438fe4d6-mg_0862-1024x683.jpg"
          alt=""
        />
      </div>

      <b-col class="list">
        <h1>{{ $t("rankings.title") }}</h1>
        <h2>{{ $t("rankings.subjects") }}</h2>
        <grid
          v-if="rankings.items.filter((e) => e.isOnline).length > 0"
          class="double articles"
        >
          <a
            v-for="ranking in rankings.items.filter((e) => e.isOnline)"
            :key="ranking.id"
            class="edge"
            :href="`/rankings/${ranking.id}`"
          >
            <h4>{{ ranking.name }}</h4>
          </a>
        </grid>
        <h4 class="mb-5" v-else>
          {{ $t("rankings.no_rankings_found") }}
        </h4>
      </b-col>
    </b-col>
  </b-row>
</template>

<script>
import {
  rankingsService,
  federationIdService,
  seasonsService,
} from "@/services";

export default {
  mixins: [rankingsService, federationIdService, seasonsService],

  async created() {
    await this.getRankings({
      params: {
        federationId: this.federationId(),
      },
    });
  },
};
</script>

<style scoped>
.list {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 55px;
  padding-right: 16px;
}

@media (max-width: 768px) {
  .list {
    margin-top: 0;
    padding-left: 16px;
  }
}
</style>
