<template>
  <b-row class="ml-0 mr-0 flex-nowrap-md">
    <b-col class="p-0" cols="12" md="3">
      <aside
        style="height: 100%; padding-top: 240px; "
        class="d-none d-md-block"
      >
        <b-col style="padding-left: 0px;">
          <h3 class="line trigger" data-target="aside">
            {{ $t("rankings.title") }}
          </h3>

          <ul>
            <li>
              <a class="active">Klassementen</a>
              <ul>
                <li
                  v-for="ranking in rankings.items.filter((e) => e.isOnline)"
                  :key="ranking.id"
                >
                  <a
                    :class="rankingName == ranking.name ? 'active' : ''"
                    :href="`/rankings/${ranking.id}`"
                    >{{ ranking.name }}</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </b-col>
      </aside>
    </b-col>
    <b-col class="p-0">
      <div class="page-header" style="margin-top: 80px">
        <img
          loading="lazy"
          src="https://storage.knrb.nl/2022/01/438fe4d6-mg_0862-1024x683.jpg"
          alt=""
        />
      </div>
      <b-col v-if="rankingGroups.length" class="list">
        <h1 class="text-break">{{ rankingName }}</h1>
        <h2 class="wp-block-heading">Klassementsstanden</h2>
        <p>
          Tussenstanden in het klassement worden op deze pagina gepubliceerd.
        </p>

        <RankingTeamCard
          v-for="group in rankingGroups"
          :key="group.id"
          :ranking-group="group"
          :ranking="rankings.items.find((e) => e.id == rankingId)"
        />
      </b-col>
      <b-col v-else>
        <h2 class="text-break m-5">{{ $t("common.ranking_is_not_online") }}</h2>
      </b-col>
    </b-col>
  </b-row>
</template>

<script>
import {
  rankingTournamentsService,
  rankingGroupsService,
  federationIdService,
  seasonsService,
  rankingsService,
} from "@/services";

import RankingTeamCard from "../Rankings/RankingTeamCard";

export default {
  mixins: [
    federationIdService,
    seasonsService,
    rankingTournamentsService,
    rankingGroupsService,
    rankingsService,
  ],
  components: {
    RankingTeamCard,
  },
  data() {
    return {
      rankingId: null,
    };
  },
  methods: {},
  computed: {
    rankingName() {
      if (
        this.rankings &&
        this.rankings.items &&
        this.rankings.items.find((e) => e.id == this.rankingId)
      ) {
        return this.rankings.items.find((e) => e.id == this.rankingId).name;
      }
      return "-";
    },
  },
  async created() {
    await this.getRankings({
      params: {
        federationId: this.federationId(),
      },
    });
    await this.getSeasons({
      params: {
        federationId: this.federationId(),
      },
    });
    var currentSeasonId = this.seasons.items.find((e) => e.isActive).id;
    this.rankingId = this.$route.params.id;
    await this.getRankingTournament({
      params: {
        id: this.rankingId,
        seasonId: currentSeasonId,
      },
    });
    await this.getRankingGroups({
      params: {
        rankingId: this.rankingId,
        seasonId: currentSeasonId,
      },
    });
  },
};
</script>

<style scoped>
.list {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 55px;
  padding-right: 16px;
}

.flex-nowrap-md {
  flex-wrap: nowrap;
}

@media (max-width: 768px) {
  .list {
    margin-top: 0;
    padding-left: 16px;
  }
  .flex-nowrap-md {
    flex-wrap: wrap;
  }
}
</style>
