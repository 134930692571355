<template>
  <div>
    <b-row v-if="!matchesLoading" class="ml-2 mb-2">
      <div class="d-flex">
        <p class="font-italic font-weight-bold blue fs-12">
          {{ `${totalResults()} ${$t("common.matches")} ` }}
        </p>
        <p class="font-italic font-weight-bold blue ml-5 fs-12">
          {{ `${totalRaces()} ${$t("common.races_lowercase")} ` }}
        </p>
      </div>
    </b-row>
    <div class="ml-2 mt-4" v-if="matchesLoading">
      <h3>
        {{ $t("common.loading_results") }}
        <div class="spinner-grow spinner-grow-sm mb-1" role="status" />
      </h3>
    </div>
    <div v-else>
      <table v-if="matchBoatCategoryCodes.length && matchGeneratedCodes.length" responsive>
        <tr>
          <th style="width: 100px"></th>
          <th
            class="color-b"
            style="width: auto"
            v-for="boatCategoryCode in matchBoatCategoryCodes"
            :key="boatCategoryCode.key"
          >
            {{ boatCategoryCode.key }}
          </th>
        </tr>
        <tr
          v-for="(matchGeneratedCode, index) in matchGeneratedCodes"
          :key="matchGeneratedCode.key + index"
        >
          <td class="font-weight-bold">
            {{ matchGeneratedCode.key }}
          </td>
          <td
            v-for="boatCategoryCode in matchBoatCategoryCodes"
            :key="boatCategoryCode.key"
          >
            <div
              @click="
                navigateToMatch(
                  computeAmountPerDay(
                    matchGeneratedCode.key,
                    boatCategoryCode.key
                  )
                )
              "
            >
              {{
                computeAmountPerDay(
                  matchGeneratedCode.key,
                  boatCategoryCode.key
                )
                  .map((x) => x.summary)
                  .join(", ")
              }}
            </div>
          </td>
        </tr>
      </table>

      <div class="text-center" v-else>{{ $t("common.no_records") }}</div>
    </div>
  </div>
</template>

<script>
import { matchesService, tournamentDaysService } from "@/services";

export default {
  mixins: [matchesService, tournamentDaysService],
  data() {
    return {
      matchBoatCategoryCodes: [],
      matchGeneratedCodes: [],
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
  methods: {
    navigateToMatch(matchGeneratedCode) {
      this.$eventHub.$emit(
        "results-navigate-to-by-match-tab",
        matchGeneratedCode
      );
    },
    totalRaces() {
      let results = this.matches.map((x) => x.races.length);
      return results.reduce((a, b) => a + b, 0);
    },
    totalResults() {
      let matchesWithResults = this.matches.filter((x) => x.races.length > 0);
      return matchesWithResults.length;
    },
    computeAmountPerDay(matchGeneratedCode, matchBoatCategoryCode) {
      let amountPerDay = [];

      this.tournamentDays.forEach((td) => {
        let amountOfMatches = this.matches.filter(
          (x) =>
            x.matchGeneratedCode === matchGeneratedCode &&
            x.matchBoatCategoryCode === matchBoatCategoryCode &&
            x.date === td.date
        );

        amountPerDay.push({
          key: this.formatDate(td.date, "EEE"),
          value: amountOfMatches.reduce((a, b) => +a + +b.races.length, 0),
          dayId: td.id,
          matchIds: amountOfMatches.map((x) => x.id),
        });
      });
      let response = [];
      amountPerDay.forEach((x) => {
        if (x.value !== 0) {
          let resp = {
            matchIds: x.matchIds,
            summary: x.value + " (" + x.key + ")",
            dayId: x.dayId,
          };
          response.push(resp);
        }
      });
      return response;
    },
    groupBy(key) {
      let storage = [];
      this.matches.forEach((item) => {
        var group = {
          key: item[key],
          items: [],
          position: item.matchBoatCategoryCodePosition,
        };
        let existing = storage.find((x) => x.key === group.key);
        if (existing) {
          existing.items.push(item);
        } else {
          storage.push(group);
        }
      });
      return storage.sort(function(a, b) {
        return a.position - b.position;
      });
    },
    async loadData() {
      this.getMatches({
        params: {
          tournamentId: this.tournamentId,
          raceResults: true,
          orderByMatchBoatCategoryCodes: true,
        },
      }).then((resp) => {
        this.matchGeneratedCodes = this.groupBy("matchGeneratedCode");
        this.matchBoatCategoryCodes = this.groupBy("matchBoatCategoryCode");
      });

      await this.getTournamentDays({
        params: {
          tournamentId: this.tournamentId,
        },
      });
    },
  },
};
</script>

<style scoped>
tr:first-child {
  border-bottom: 1px solid var(--light-blue);
}
th:nth-child(2),
td:nth-child(2) {
  border-left: 1px solid var(--light-blue);
}

/* hovering options */
table {
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
td,
th {
  cursor: pointer;
  padding: 10px;
  position: relative;
}
th,
td {
  width: auto;
}
td:hover::before,
th:hover::before {
  background-color: #d6edf57a;
  content: "\00a0";
  height: 100%;
  left: -5000px;
  position: absolute;
  top: 0;
  width: 10000px;
  z-index: -1;
}
td:hover::after,
th:hover::after {
  background-color: #d6edf575;
  content: "\00a0";
  height: 10000px;
  left: 0;
  position: absolute;
  top: -5000px;
  width: 100%;
  z-index: -1;
}
</style>
