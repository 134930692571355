<template>
  <div>
    <p v-if="!matchesLoading" class="font-weight-bold blue fs-14 mt-2 mb-2">
      {{ `${totalRegistrations()} ${$t("common.registrations_lowercase")} ` }}
    </p>
    <tournament-days-card @fetchData="loadData"></tournament-days-card>
    <div class="ml-2 mt-4" v-if="matchesLoading">
      <h3>
        {{ $t("common.loading_registrations") }}
        <div class="spinner-grow spinner-grow-sm mb-1" role="status" />
      </h3>
    </div>
    <b-table
      v-else
      :fields="fields"
      :items="matches"
      show-empty
      borderless
      responsive
      :empty-text="$t('common.no_records')"
    >
      <template #cell(code)="item">
        {{
          item.item.codeAddition
            ? `${item.item.code}, ${item.item.codeAddition}`
            : item.item.code
        }}
      </template>
      <template #cell(teams)="row">
        {{ joinTeamNames(row) }}
      </template>
      <template #cell(expand-registrations)="row">
        <b-link v-if="row.item.registrations.length">
          <b-icon-chevron-down
            v-if="!row.detailsShowing"
            class="color-b"
            @click="row.toggleDetails"
          />
          <b-icon-chevron-up
            v-else
            class="color-b"
            @click="row.toggleDetails"
          />
        </b-link>
        <b-form-checkbox
          class="d-none"
          v-model="row.detailsShowing"
          @change="row.toggleDetails"
        >
        </b-form-checkbox>
      </template>
      <template #row-details="row">
        <registration-details-card
          :registrations="row.item.registrations"
          :base-row="row"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { matchesService } from "@/services";
import { RegistrationDetailsCard } from "@/app/Registrations";

export default {
  mixins: [matchesService],
  components: { RegistrationDetailsCard },
  props: {
    filter: {
      type: Array,
      defaultValue: [],
    },
  },
  data() {
    return {
      fields: [
        {
          label: "#",
          key: "number",
          thStyle: "width:20px;",
          thClass: "color-b",
        },
        {
          label: this.$t("common.match_code"),
          key: "code",
          thStyle: "width:100px;",
          thClass: "color-b",
        },
        {
          label: "",
          key: "alternativeName",
          thClass: "color-b",
        },
        {
          label: "",
          key: "matchNameWithAddition",
          thStyle: "width:275px;",
          thClass: "color-b",
        },
        {
          label: this.$t("common.teams"),
          key: "teams",
          thClass: "color-b",
        },
        {
          label: "",
          key: "expand-registrations",
          thStyle: "min-width: 50px;",
        },
        {
          label: this.$t("common.#_registrations"),
          key: "registrationCount",
          thStyle: "min-width: 150px;",
          thClass: "color-b",
        },
      ],
    };
  },
  methods: {
    totalRegistrations() {
      return this.matches
        .map((x) => x.registrationCount)
        .reduce((a, b) => a + b, 0);
    },
    joinTeamNames(row) {
      let teamNames = [];
      row.item.registrations.forEach((registration) => {
        if (registration.teams) {
          registration.teams.forEach((team) => {
            teamNames.push(team.teamFullName);
          });
        }
      });
      return teamNames.join(", ");
    },
    async loadData(tournamentDayId, fromNav) {
      if (this.filter && !fromNav) {
        this.$eventHub.$emit("update-tournament-day", this.filter[0].dayId);
        this.getMatches({
          params: {
            tournamentId: this.tournamentId,
            tournamentDayId: this.filter[0].dayId,
            matchRegistrations: true,
            matchIds: this.filter[0].matchIds,
          },
        });
      } else {
        this.getMatches({
          params: {
            tournamentId: this.tournamentId,
            tournamentDayId: tournamentDayId,
            matchRegistrations: true,
          },
        });
      }
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
};
</script>
