<template>
  <b-overlay :show="matchesLoading">
    <b-row class="m-2">
      <div class="d-flex">
        <p class="font-weight-bold blue fs-14 mt-2 mb-2">
          {{
            `${totalRegistrations()} ${$t("common.registrations_lowercase")} `
          }}
        </p>
      </div>
    </b-row>
    <b-table
      :fields="fields"
      :items="matches"
      show-empty
      borderless
      responsive
      :empty-text="$t('common.no_records')"
      small
    >
      <template #cell(code)="item">
        {{
          item.item.codeAddition
            ? `${item.item.code}, ${item.item.codeAddition}`
            : item.item.code
        }}
      </template>
      <template #cell(codeInfo)="item">
        {{ item.item.matchNameWithAddition }}
      </template>
      <template #cell(teams)="item">
        {{ joinTeamNames(item) }}
      </template>
    </b-table>
  </b-overlay>
</template>

<script>
import { matchesService } from "@/services";

export default {
  mixins: [matchesService],
  data() {
    return {
      fields: [
        {
          label: "#",
          key: "position",
          thStyle: "width:5%",
          thClass: "color-b",
        },
        {
          label: this.$t("common.match_code"),
          key: "code",
          thClass: "color-b",
          thStyle: "width:10%",
        },
        {
          label: "",
          key: "codeInfo",
          thClass: "color-b",
          thStyle: "width:20%",
        },
        {
          label: this.$t("common.teams"),
          key: "teams",
          thClass: "color-b",
          thStyle: "width:45%",
        },
        {
          label: this.$t("common.#_registrations"),
          key: "registrationCount",
          thClass: "color-b",
          thStyle: "width: 15%",
        },
      ],
    };
  },
  methods: {
    totalRegistrations() {
      return this.matches
        .map((x) => x.registrationCount)
        .reduce((a, b) => a + b, 0);
    },
    joinTeamNames(row) {
      let teamNames = [];
      row.item.registrations.forEach((registration) => {
        if (registration.teams) {
          registration.teams.forEach((team) => {
            teamNames.push(team.teamFullName);
          });
        }
      });
      return teamNames.join(", ");
    },
    async loadData() {
      await this.getMatches({
        params: {
          tournamentId: this.tournamentId,
          orderByPosition: true,
        },
      });
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
};
</script>
