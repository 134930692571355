import nl from "date-fns/locale/nl";
import Vue from 'vue';
import { getShortLocaleCode } from "@/i18n";
import { format } from 'date-fns'

var localize = getShortLocaleCode();

Vue.filter("dateLocalized", function (value, newFormat) {
    if (localize === 'nl') {
        return format(new Date(value), newFormat, { locale: nl });
    } else {
        return format(new Date(value), newFormat, null);
    }
})