<template>
  <div>
    <p v-if="!raceTeamsLoading" class="font-italic font-weight-bold blue fs-12">
      {{ `${totalRaces()} ${$t("common.races_lowercase")} ` }}
    </p>
    <h6 class="color-b">{{ organisationName }}</h6>
    <tournament-days-card @fetchData="loadData" />
    <div class="ml-2 mt-4" v-if="raceTeamsLoading">
      <h3>
        {{ $t("common.loading_results") }}
        <div class="spinner-grow spinner-grow-sm mb-1" role="status" />
      </h3>
    </div>
    <b-table
      v-else
      :fields="fields"
      :items="raceTeams"
      fixed
      show-empty
      borderless
      :empty-text="$t('common.no_records')"
      small
      responsive
    >
      <template #cell(stroke)="row">
        <div
          v-for="(teamMember, index) in row.item.teamVersion.teamMembers"
          :key="keyCode(index)"
        >
          <router-link
            :to="{
              name: 'ResultsPerPerson',
              params: { guid: teamMember.person.personId },
            }"
          >
            {{ teamMember.isStroke ? teamMember.person.fullName : "" }}
          </router-link>
        </div>
      </template>
      <template #cell(time)="row">
        {{ computeMinutes(row.item.raceTeamTimes[0].time) }}
      </template>
      <template #cell(teamName)="row">
        {{ row.item.teamVersion.matchTeam.name }}
      </template>
      <template #cell(results)="row">
        <b-link v-if="row.item.race">
          <b-icon-chevron-down
            v-if="!row.detailsShowing"
            class="color-b"
            @click="row.toggleDetails"
          />
          <b-icon-chevron-up
            v-else
            class="color-b"
            @click="row.toggleDetails"
          />
        </b-link>
        <b-form-checkbox
          class="d-none"
          v-model="row.detailsShowing"
          @change="row.toggleDetails"
        >
        </b-form-checkbox>
      </template>
      <template #row-details="row">
        <result-details-card :races="[row.item.race]" />
      </template>
      <template #cell(organisationName)>
        {{ organisationName }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { addSeconds } from "date-fns";
import { raceTeamsService } from "@/services";
import { ResultDetailsCard } from "@/app/RaceResults";

export default {
  mixins: [raceTeamsService],
  components: { ResultDetailsCard },
  props: {
    organisationName: String,
    organisationId: String,
  },
  data() {
    return {
      fields: [
        { label: "#", key: "race.match.number", thClass: "color-b" },
        {
          label: this.$t("common.match_code"),
          key: "race.match.code",
          thClass: "color-b",
        },
        {
          label: this.$t("common.team"),
          key: "teamName",
          thClass: "color-b",
        },
        {
          label: this.$t("common.stroke"),
          key: "stroke",
          thClass: "color-b",
        },
        {
          label: this.$t("common.race"),
          key: "race.name",
          thClass: "color-b",
        },
        {
          label: this.$t("common.lane"),
          key: "lane",
          thClass: "color-b",
        },
        {
          label: this.$t("common.time"),
          key: "time",
          thClass: "color-b",
        },
        {
          label: this.$t("common.position"),
          key: "endPosition",
          thClass: "color-b",
        },
        {
          label: this.$t("common.results"),
          key: "results",
          thClass: "color-b",
        },
      ],
    };
  },
  methods: {
    computeMinutes(currentMilestone) {
      let defaultDate = new Date(0);
      let formattedDate = this.formatDate(
        addSeconds(defaultDate, currentMilestone),
        "mm:ss"
      );
      return formattedDate;
    },
    totalRaces() {
      return this.raceTeams.map((x) => x.race).length;
    },
    async loadData(tournamentDayId) {
      this.getRaceTeams({
        params: {
          tournamentDayId: tournamentDayId,
          tournamentId: this.tournamentId,
          organisationId: this.organisationId,
        },
      }).then((resp) => {
        resp.data.forEach((data) => {
          data.race.raceTeams.push({
            boatName: data.boatName,
            bonusTime: data.bonusTime,
            code: data.code,
            endPosition: data.endPosition,
            endTime: data.endTime,
            id: data.id,
            lane: data.lane,
            number: data.number,
            oarBladeUrl: data.oarBladeUrl,
            race: data.race,
            raceTeamTimes: data.raceTeamTimes,
            teamVersion: data.teamVersion,
            teamVersionId: data.teamVersionId,
          });
          data.race.raceTeams.forEach((raceTeam) => {
            raceTeam.raceTeamTimes.sort(function(a, b) {
              return a.distance - b.distance;
            });
          });
        });
      });
    },
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped>
th:nth-child(4),
td:nth-child(4) {
  border-left: 0.5px solid var(--light-blue);
}

tr .body:hover {
  background-color: #d2d3f0;
}

td {
  margin-left: 5px;
  padding-left: 30px;
  padding-top: 2px;
  padding-right: 5px;
  padding-bottom: 5px;
}
</style>
