import { mapGetters, mapActions } from 'vuex';

export const tournamentCategoriesService = {
    computed: {
        ...mapGetters([
            'tournamentCategories', 'tournamentCategoriesLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getTournamentCategories'
        ])
    }
}