import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const person = new BaseStoreBuilder({
  baseURL: appSettings.BaseUrl,
  entityName: "person"
})
  .getEntityById()
  .get({ path: () => `/persons/search`, queryParams: true }, 'searchPersons')
  .build();

export default person;