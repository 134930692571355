import Vue from "vue";
import Vuex from "vuex";
import { appSettings } from "@/appSettings";

import tournaments from './tournaments';
import seasons from './seasons';
import tournamentTypes from './tournamentTypes';
import tournamentCategories from './tournamentCategories';
import matches from './matches';
import tournamentDays from './tournamentDays';
import races from './races';
import organisations from './organisations';
import person from './person';
import raceTeams from './raceTeams';
import matchTeams from './matchTeams';
import rankings from './rankings';
import rankingGroups from './rankingGroups';
import rankingTournaments from './rankingTournaments';
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
  ],
  state: {
    FederationId: appSettings.FederationId
  },
  mutations: {},
  actions: {
  },
  modules: {
    tournaments,
    seasons,
    tournamentTypes,
    tournamentCategories,
    matches,
    tournamentDays,
    races,
    organisations,
    person,
    raceTeams,
    matchTeams,
    rankings,
    rankingGroups,
    rankingTournaments,
  }
});
