<template>
  <div style="margin-bottom: 20px; padding-top: 150px;">
    <b-overlay :show="tournamentLoading" rounded="sm">
      <div class="d-flex justify-content-between">
        <div>
          <h2 class="color-b">
            <b-breadcrumb
              :items="breadcrumbs"
              style="background-color: #fafbfc; padding: 0px"
            />
          </h2>
          <div v-if="tournament.publicationStatus === 'Published'">
            {{
              `${$t("common.status")}: ${
                tournamentRegistrationStatusLabels[
                  tournament.publicTournamentStatus
                ]
              } ${getPublicTournamentStatusDate(
                tournament.publicTournamentStatus
              )}`
            }}
          </div>
          <div v-else>
            {{
              `${$t("common.status")}: ${$t("common.details_not_available")}`
            }}
          </div>
          <div v-if="tournament.publicationStatus === 'Published'">
            {{
              `${$t("common.date")}: ${wrapDate(tournament).days} ${
                wrapDate(tournament).months
              } ${wrapDate(tournament).years}`
            }}
          </div>
          <div v-else>
            {{
              `${$t("common.date")}: ${getLocalizedDate(
                tournament.firstTournamentDate
              )}`
            }}
          </div>
        </div>
        <div
          v-if="tournament.publicationStatus === 'Published'"
          class="float-right"
        >
          <img
            v-if="tournament.pictureUrl"
            :src="tournament.pictureUrl"
            fluid
            class="center-block img-responsive img-size"
          />
        </div>
      </div>
    </b-overlay>

    <b-tabs v-if="tournament.publicationStatus === 'Published'" card>
      <b-tab
        :active="tab === 'details'"
        :title="$t('common.details')"
        @click="updateRouting('details')"
        lazy
      >
        <div class="section-split"></div>
        <tournament-details-card
          @click="updateRouting('details')"
          :tournament="tournament"
        />
      </b-tab>
      <b-tab
        :active="tab === 'events'"
        @click="updateRouting('events')"
        :title="$t('tournaments.events')"
        lazy
      >
        <div class="section-split"></div>
        <matches-card />
      </b-tab>
      <b-tab
        lazy
        v-if="tournament.hasRegistrations"
        :title="$t('common.registrations')"
        class="tab-pane-border "
        @click="updateRouting('registrations')"
        :active="tab === 'registrations'"
      >
        <registrations-card />
      </b-tab>
      <b-tab
        lazy
        v-if="tournament.hasResults"
        :title="$t('common.results')"
        class="tab-pane-border"
        :active="tab === 'results'"
        @click="updateRouting('results')"
      >
        <race-results-card
          :sub-menu="subMenu"
          :race-id="raceId"
          :tournament-day-id="tournamentDayId"
          :registration-system-type-name="registrationSystemTypeName"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { tournamentsService } from "@/services";
import { MatchesCard } from "@/app/Matches";
import { TournamentDetailsCard } from "@/app/Tournaments";
import { RaceResultsCard } from "@/app/RaceResults";
import { RegistrationsCard } from "@/app/Registrations";
import { tournamentRegistrationStatusEnum } from "@/app/shared";

export default {
  mixins: [tournamentsService, tournamentRegistrationStatusEnum],
  components: {
    MatchesCard,
    TournamentDetailsCard,
    RaceResultsCard,
    RegistrationsCard,
  },
  data() {
    return {
      registrationSystemTypeName: "",
    };
  },
  props: {
    tab: {
      type: String,
      default: "details",
    },
    subMenu: {
      type: String,
      default: "matrix",
    },
    raceId: {
      type: Number,
      default: 0,
    },
    tournamentDayId: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    getLocalizedDate(date, format) {
      if (!format) {
        return this.$options.filters.dateLocalized(date, "dd MMMM yyyy");
      } else {
        return this.$options.filters.dateLocalized(date, format);
      }
    },
    getPublicTournamentStatusDate(status) {
      var date = null;
      if (status == this.tournamentRegistrationStatuses.RegistrationOpensOn) {
        date = this.tournament.registrationOpenAt;
      }
      if (status == this.tournamentRegistrationStatuses.RegistrationOpenUntil) {
        date = this.tournament.registrationCloseAt;
      }
      if (status == this.tournamentRegistrationStatuses.ChangesPossibleUntil) {
        date = this.tournament.changesCloseAt;
      }

      if (date != null) {
        return this.$options.filters.dateLocalized(
          date,
          "dd MMMM yyyy - HH:mm"
        );
      } else {
        return "";
      }
    },
    updateRouting(tab) {
      if (tab !== "details" && tab !== "events") {
        this.$router.replace({
          query: { tab: tab, subMenu: "matrix" },
        });
      } else {
        this.$router.replace({
          query: { tab: tab },
        });
      }
    },
    async loadData() {
      await this.getTournament({
        params: {
          id: this.tournamentId,
        },
      }).then((x) => {
        this.registrationSystemTypeName = x.data.registrationSystemTypeName;
      });
    },
    wrapDate(tournament) {
      if (tournament.firstTournamentDate) {
        let firstDayMonth = this.getLocalizedDate(
          tournament.firstTournamentDate,
          "dd/MMMM/yyyy"
        );
        let firstDay = firstDayMonth.split("/")[0].replace(/^0+/, "");
        let firstMonth = firstDayMonth.split("/")[1];
        let firstYear = firstDayMonth.split("/")[2];

        if (tournament.lastTournamentDate) {
          let lastDayMonth = this.getLocalizedDate(
            tournament.lastTournamentDate,
            "dd/MMMM"
          );
          let lastDay = lastDayMonth.split("/")[0].replace(/^0+/, "");
          let lastMonth = lastDayMonth.split("/")[1];

          if (firstDay === lastDay) {
            if (firstMonth === lastMonth) {
              return {
                days: `${firstDay}`,
                months: `${firstMonth}`,
                years: firstYear,
              };
            } else {
              return {
                days: `${firstDay} - ${lastDay}`,
                months: `${firstMonth}`,
                years: firstYear,
              };
            }
          } else {
            if (firstMonth === lastMonth) {
              return {
                days: `${firstDay} - ${lastDay}`,
                months: `${firstMonth}`,
                years: firstYear,
              };
            } else {
              return {
                days: `${firstDay} ${firstMonth}`,
                months: `- ${lastDay} ${lastMonth}`,
                years: firstYear,
              };
            }
          }
        }
      }
      return {
        days: "",
        months: "-",
      };
    },
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("common.calender"),
          href: "/",
        },
        {
          text: this.tournament.name
            ? this.tournament.name.length > 50
              ? `${this.tournament.name.substring(0, 50)}...`
              : this.tournament.name
            : "",
          href: "#",
        },
      ];
    },
  },
  created() {
    this.loadData();
  },
};
</script>
