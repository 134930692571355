import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const tournamentCategories = new BaseStoreBuilder({
  baseURL: appSettings.BaseUrl,
  entityName: "tournamentCategory",
  resourceName: "tournament-categories"
})
  .getEntitiesList()
  .build();

export default tournamentCategories;