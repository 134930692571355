<template>
  <b-tabs align="center" lazy>
    <div class="section-split"></div>
    <b-tab
      @click="regenerateComponentKey()"
      title-link-class="custom-nav"
      :title="$t('common.per_matrix')"
      lazy
    >
      <registration-by-matrix-card />
      <!-- Could use this the show oarBlade better -->
      <!-- style="background-color: #f8f8ff"  -->
    </b-tab>
    <b-tab
      :active="tab === 'match'"
      @click="regenerateComponentKey()"
      title-link-class="custom-nav"
      :title="$t('common.per_match')"
      lazy
    >
      <registration-by-match-card :filter="filter" />
    </b-tab>
    <b-tab
      title-link-class="custom-nav"
      @click="regenerateComponentKey()"
      :title="$t('common.per_club')"
      lazy
    >
      <registration-by-club-card :key="keyCode(key)" />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  RegistrationByClubCard,
  RegistrationByMatchCard,
  RegistrationByMatrixCard,
} from "@/app/Registrations";

export default {
  components: {
    RegistrationByClubCard,
    RegistrationByMatchCard,
    RegistrationByMatrixCard,
  },
  methods: {
    regenerateComponentKey(key) {
      this.tab = "";
      this.key = this.keyCode(key).toString();
    },
  },
  created() {
    this.$eventHub.$on("registrations-navigate-to-by-match-tab", (event) => {
      this.filter = event;
      this.tab = "match";
    });
  },
  data() {
    return {
      key: String,
      tab: "",
      filter: null,
    };
  },
};
</script>
