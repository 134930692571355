import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const rankingGroups = new BaseStoreBuilder({
  baseURL: appSettings.BaseUrl,
  entityName: "rankingGroup",
  resourceName: "rankingGroups"
})
  .getEntitiesList()
  .build();

export default rankingGroups;