import { mapGetters, mapActions } from 'vuex';

export const rankingTournamentsService = {
    computed: {
        ...mapGetters([
            'rankingTournaments', 'rankingTournamentsLoading',
            'rankingTournament', 'rankingTournamentLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getRankingTournaments',
            'getRankingTournament'
        ])
    }
}