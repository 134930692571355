<template>
  <b-row>
    <b-col class="p-0" cols="12" md="3">
      <aside
        style="height: 100%; padding-top: 240px;"
        class="d-none d-md-block"
      >
        <b-col style="padding-left: 0px;">
          <h3 class="line trigger" data-target="aside">
            {{ $t("common.season") }}
          </h3>
          <seasons-navigation
            class="pl-3"
            @success="fetchTournaments('', $event.seasonId)"
          />
        </b-col>
        <b-col style="padding-left: 0px;" class="pt-4">
          <h3 class="line trigger" data-target="aside">
            {{ $t("common.filters") }}
          </h3>
        </b-col>
        <b-col style="padding-left: 0px;">
          <label>
            {{ $t("common.search_for_tournament") }}
          </label>
          <b-form-input @input="filterTournaments($event)" />
          <label>
            {{ $t("common.filter_by") }}
          </label>
          <b-col>
            <b-row>
              <b-button
                variant="secondary"
                ref="buttonRegistrations"
                :pressed.sync="registrationsBtnPressed"
                @click="applyFilter('registration')"
                >{{ $t("common.registrations") }}
              </b-button>

              <b-button
                class="ml-2"
                variant="secondary"
                ref="buttonResults"
                :pressed.sync="resultsBtnPressed"
                @click="applyFilter('resultsAvailable')"
                >{{ $t("common.results") }}
              </b-button>
            </b-row>
          </b-col>
          <base-multiselect
            class="pt-2"
            :label="$t('common.categories')"
            :label-cols-sm="'12'"
            :settings="{
              options: tournamentCategories,
              label: 'name',
              multiple: true,
              taggable: false,
              trackBy: 'id',
            }"
            v-model="chosenCategories"
            value-field="id"
          />
          <!-- <b-col class="pt-2">
            <b-row>
              <h4>
                <b-link class="pt-1" :href="`/person-search`">{{
                  $t("common.search_for_person")
                }}</b-link>
              </h4>
            </b-row>
          </b-col> -->
        </b-col>
      </aside>
    </b-col>
    <b-col class="p-0">
      <div class="page-header">
        <img
          src="https://storage.knrb.nl/2022/01/438fe4d6-mg_0862-1024x683.jpg"
          alt=""
        />
      </div>

      <div class="filters d-md-none mb-4">
        <section class="d-flex flex-column">
          <b-collapse v-model="filtersVisible">
            <div class="d-flex flex-column mb-5">
              <h3 class="line trigger" data-target="aside">
                {{ $t("common.season") }}
              </h3>
              <seasons-navigation
                class="pl-3"
                @success="fetchTournaments('', $event.seasonId)"
              />
            </div>

            <div class="d-flex flex-column mb-5">
              <h3 class="line trigger" data-target="aside">
                {{ $t("common.filters") }}
              </h3>
              <div class="d-flex flex-column">
                <label>
                  {{ $t("common.search_for_tournament") }}
                </label>
                <b-form-input @input="filterTournaments($event)" />
              </div>

              <div class="d-flex flex-column">
                <label>
                  {{ $t("common.filter_by") }}
                </label>
                <div class="d-flex">
                  <b-button
                    class="mr-1"
                    variant="secondary"
                    ref="buttonRegistrations"
                    :pressed.sync="registrationsBtnPressed"
                    @click="applyFilter('registration')"
                    >{{ $t("common.registrations") }}
                  </b-button>

                  <b-button
                    class="ml-2"
                    variant="secondary"
                    ref="buttonResults"
                    :pressed.sync="resultsBtnPressed"
                    @click="applyFilter('resultsAvailable')"
                    >{{ $t("common.results") }}
                  </b-button>
                </div>
              </div>

              <base-multiselect
                class="pt-2"
                :label="$t('common.categories')"
                :label-cols-sm="'12'"
                :settings="{
                  options: tournamentCategories,
                  label: 'name',
                  multiple: true,
                  taggable: false,
                  trackBy: 'id',
                }"
                v-model="chosenCategories"
                value-field="id"
              />
            </div>
          </b-collapse>

          <b-button @click="filtersVisible = !filtersVisible">
            {{
              filtersVisible
                ? $t("common.hide_filters")
                : $t("common.show_filters")
            }}
          </b-button>
        </section>
      </div>

      <b-col class="list">
        <b-row>
          <h1 class=" mb-0">{{ $t("common.calender") }}</h1>
        </b-row>
        <div
          v-if="!tournamentsLoading && !seasonsLoading"
          class="mt-2 mb-2 d-flex"
        >
          <span class="font-italic fs-12 pt-1 mr-2">
            {{
              `${visibleTournamentsCount} ${$t(
                "common.out_of"
              )}  ${totalCount} ${$t("common.rowing_events")} `
            }}
          </span>
          -
          <div v-if="this.currentSeason.isActive" class="d-flex ml-2 ">
            <b-form-checkbox
              size="sm"
              v-model="showAllActiveSeason"
              id="showAllActiveSeason"
              name="showAllActiveSeason"
            />
            <label for="showAllActiveSeason" class="font-italic fs-12 pt-1">
              {{ $t("common.show_all_this_season") }}
            </label>
          </div>
        </div>

        <b-row v-if="tournamentsLoading || seasonsLoading" class="mt-5">
          <b-col>
            <h3>
              {{ $t("common.loading_tournaments") }}
              <div class="spinner-grow spinner-grow-sm mb-1" role="status" />
            </h3>
          </b-col>
        </b-row>

        <div v-else>
          <b-row
            class="mt-4"
            v-for="month in tournamentsPerMonth"
            :key="month.name"
          >
            <h3 v-if="month.items.length > 0" class="line">{{ month.name }}</h3>
            <b-table
              v-if="month.items.length > 0"
              :fields="fields"
              :items="month.items"
              show-empty
              :empty-text="$t('common.no_records')"
              thead-class="d-none"
              responsive
            >
              <template v-slot:cell(briefInfo)="{ item, index }">
                <div
                  class="badge"
                  :style="{
                    'background-color': index % 2 ? '#3362c0' : '#003bb0',
                  }"
                >
                  <div class="mt-4">
                    {{ wrapDate(item).firstRow.replace(/^0+/, "") }}
                    <br />
                    {{ wrapDate(item).secondRow }}
                  </div>
                </div>
              </template>
              <template v-slot:cell(name)="{ item }">
                <b-col>
                  <b-row>
                    <h4>
                      <b-link
                        class="pt-1"
                        :href="`/tournament-details/${item.id}?tab=details`"
                        >{{ item.name }}</b-link
                      >
                    </h4>
                  </b-row>
                </b-col>

                <p class="pr-5" v-if="item.publicationStatus === 'Published'">
                  {{ introDescription(item.description) }}
                </p>
              </template>
              <template v-slot:cell(status)="{ item }">
                <div v-if="item.publicationStatus === 'Published'">
                  <div
                    style="color: var(--light-blue)"
                    class="text-center fs-15"
                  >
                    <h5>
                      {{
                        item.publicationStatus !== "NotPublished"
                          ? tournamentRegistrationStatusLabels[
                              item.publicTournamentStatus
                            ]
                          : ""
                      }}
                    </h5>
                  </div>
                  <div
                    v-if="
                      item.publicTournamentStatus ===
                        tournamentRegistrationStatuses.RegistrationOpensOn
                    "
                    style="color: var(--light-blue)"
                    class="text-center fs-12"
                  >
                    {{ getLocalizedDate(item.registrationOpenAt) }}
                  </div>
                  <div
                    v-if="
                      item.publicTournamentStatus ===
                        tournamentRegistrationStatuses.RegistrationOpenUntil
                    "
                    style="color: var(--light-blue)"
                    class="text-center fs-12"
                  >
                    {{ getLocalizedDate(item.registrationCloseAt) }}
                  </div>
                  <div
                    v-if="
                      item.publicTournamentStatus ===
                        tournamentRegistrationStatuses.ChangesPossibleUntil
                    "
                    style="color: var(--light-blue)"
                    class="text-center fs-12"
                  >
                    {{ getLocalizedDate(item.changesCloseAt) }}
                  </div>
                  <div class="text-center mt-4">
                    <b-button-group>
                      <b-button
                        v-if="item.hasRegistrations"
                        variant="primary"
                        size="sm"
                        :href="
                          `/tournament-details/${item.id}?tab=registrations`
                        "
                        >{{ $t("common.registrations") }}
                      </b-button>
                      <b-button
                        v-if="item.hasResults"
                        :href="`/tournament-details/${item.id}?tab=results`"
                        class="ml-2"
                        variant="success"
                        size="sm"
                        >{{ $t("common.results") }}
                      </b-button>
                    </b-button-group>
                  </div>
                </div>
              </template>
              <template v-slot:cell(logo)="{ item }">
                <div
                  class="img-container text-center d-none d-md-block"
                  style="width: 100px;"
                >
                  <img :src="item.pictureUrl" fluid class="img-size" />
                </div>
              </template>
            </b-table>
          </b-row>
        </div>
      </b-col>
    </b-col>
  </b-row>
</template>

<script>
import {
  tournamentsService,
  federationIdService,
  tournamentCategoriesService,
  seasonsService,
} from "@/services";

import { tournamentRegistrationStatusEnum } from "@/app/shared";

export default {
  mixins: [
    tournamentsService,
    federationIdService,
    seasonsService,
    tournamentCategoriesService,
    tournamentRegistrationStatusEnum,
  ],
  data() {
    return {
      visibleTournamentsCount: 0,
      resultsBtnPressed: false,
      registrationsBtnPressed: false,
      editedItem: {},
      currentSeasonId: {},
      currentSeason: {},
      searchPerson: {},
      showAllActiveSeason: false,
      chosenCategories: [],
      filtersToApply: [],
      tournamentsPerMonth: [],
      totalCount: 0,
      fields: [
        {
          label: "",
          key: "briefInfo",
          tdClass: "pl-0",
          tdStyle: "width:1px; white-space:nowrap;",
        },
        { label: "", key: "name" },
        { label: "", key: "status", tdClass: "d-none d-md-block" },
        { label: "", key: "logo", tdClass: "" },
      ],
      filtersVisible: false,
    };
  },
  methods: {
    getLocalizedDate(date) {
      let localizedDate = this.$options.filters.dateLocalized(
        date,
        "dd MMMM yyyy - HH:mm"
      );
      return localizedDate;
    },
    async loadData() {
      await this.getTournamentCategories().then((response) => {
        if (response.data) {
          response.data.forEach((i) => {
            i.pressed = false;
          });
        }
      });
    },
    filterTournaments(searchString) {
      this.fetchTournaments(searchString, this.currentSeasonId);
    },
    introDescription(description) {
      if (description != null && description.length > 200) {
        return `${description.substring(0, 197)}...`;
      } else {
        return description;
      }
    },
    applyFilter(categorie) {
      if (categorie.pressed) {
        this.filtersToApply.push(categorie.id);
      } else {
        this.filtersToApply = this.filtersToApply.filter(
          (x) => x !== categorie.id
        );
      }
      this.fetchTournaments("", this.currentSeasonId);
    },
    wrapDate(tournament, fullMonthWithYear) {
      let firstDayMonth = this.formatDate(
        tournament.firstTournamentDate,
        fullMonthWithYear ? "dd/MMMM/yyyy" : "dd/MMM"
      );
      let firstDay = firstDayMonth.split("/")[0];
      let firstMonth = firstDayMonth.split("/")[1];
      let year = firstDayMonth.split("/")[2];

      return {
        firstRow: `${firstDay}`,
        secondRow: `${firstMonth}`,
        year: fullMonthWithYear ? year : "",
      };
    },
    fetchTournaments(searchString, seasonId) {
      this.currentSeasonId = seasonId;
      this.getTournaments({
        params: {
          federationId: this.federationId(),
          seasonId: seasonId,
          searchString: searchString,
          tournamentCategoryIds: this.chosenCategories,
          pageSize: 1000,
          registrationsFilter: this.registrationsBtnPressed,
          resultsFilter: this.resultsBtnPressed,
        },
      }).then((r) => {
        const month = [
          "Januari",
          "Februari",
          "Maart",
          "April",
          "Mei",
          "Juni",
          "July",
          "Augustus",
          "September",
          "Oktober",
          "November",
          "December",
        ];

        this.tournamentsPerMonth = [];

        this.currentSeason = this.seasons.items.find(
          (e) => e.id == this.currentSeasonId
        );

        var currentDate = new Date();
        var currentMonth = 0;
        if (this.currentSeason.isActive && !this.showAllActiveSeason) {
          currentMonth = currentDate.getMonth();
        }

        for (let i = currentMonth; i <= 11; i++) {
          var obj = {
            name: `${month[i]} ${this.currentSeason.name}`,
            items: r.data.items.filter(
              (e) => new Date(e.firstTournamentDate).getMonth() == i
            ),
          };

          this.tournamentsPerMonth.push(obj);
        }

        this.visibleTournamentsCount = [].concat.apply(
          [],
          this.tournamentsPerMonth.map((e) => e.items)
        ).length;
        this.totalCount = r.data.totalCount;
      });
    },
  },
  async created() {
    await this.loadData();
  },
  mounted() {
    if (this.$route.query.filter == "results") {
      this.$refs.buttonResults.click();
    }

    if (this.$route.query.filter == "registrations") {
      this.$refs.buttonRegistrations.click();
    }
  },
  watch: {
    showAllActiveSeason: {
      handler(newValue, oldValue) {
        this.fetchTournaments("", this.currentSeasonId);
      },
    },
    chosenCategories: {
      handler(newValue, oldValue) {
        this.fetchTournaments("", this.currentSeasonId);
      },
    },
  },
};
</script>

<style scoped>
.page-header {
  margin-top: 80px;
}
.list {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 55px;
  padding-right: 16px;
}

@media (max-width: 768px) {
  .page-header {
    margin-top: 120px;
    height: unset;
  }
  .list {
    margin-top: 0;
    padding-left: 16px;
  }
  .row {
    padding: 0;
    margin: 0;
  }
}
.filters {
  background: var(--color-dark-blue);
  color: #fff;
}
</style>
