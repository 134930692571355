<template>
  <b-row class="ml-0 mr-0">
    <b-col class="p-0" cols="12" md="3">
      <aside
        style="height: 100%; padding-top: 240px;"
        class="d-none d-md-block"
      >
        <b-col style="padding-left: 0px; ">
          <h3 class="line trigger" data-target="aside">
            {{ $t("common.search_for_person") }}
          </h3>
          <b-input-group class="flex-column">
            <b-form-input
              class="form-control w-full search-person-input"
              :placeholder="$t('common.search_for_person')"
              @keyup.enter="searchPerson"
              @input="setPerson($event)"
            ></b-form-input>
            <b-button
              variant="primary"
              class="search-person-btn"
              @click="searchPerson"
            >
              {{ $t("common.search") }}
            </b-button>
          </b-input-group>
        </b-col>
      </aside>
    </b-col>

    <b-col class="p-0">
      <div class="page-header" style="margin-top: 80px">
        <img
          loading="lazy"
          src="https://storage.knrb.nl/2022/04/20190629-5D4_3935-1024x683.jpg"
          alt=""
        />
      </div>
      <b-row class=" m-0 mb-3 d-block d-md-none">
        <b-input-group class="flex-column list">
          <b-form-input
            class="form-control w-full search-person-input"
            :placeholder="$t('common.search_for_person')"
            @keyup.enter="searchPerson"
            @input="setPerson($event)"
          ></b-form-input>
          <b-button
            variant="primary"
            class="search-person-btn"
            @click="searchPerson"
          >
            {{ $t("common.search") }}
          </b-button>
        </b-input-group>
      </b-row>
      <b-row class="list mt-5">
        <h1>{{ $t("common.search_for_person") }}</h1>
        <b-table
          :items="items"
          :fields="fields"
          :busy="searchPersonsLoading"
          show-empty
          :empty-text="$t('common.use_filter_persons')"
          small
          responsive
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner variant="primary" class="align-middle"></b-spinner>
              <strong class="pl-2">{{ $t("common.loading") }}</strong>
            </div>
          </template>
          <template #cell(fullName)="{ item }">
            <router-link
              :to="{
                name: 'ResultsPerPerson',
                params: { guid: item.personId },
              }"
            >
              {{ item.fullName }}
            </router-link>
          </template>
        </b-table>
        <b-container v-if="tableTotalCount > tableOptions.tablePageSize" fluid>
          <b-row>
            <b-col cols="auto" class="mx-2 text-left ml-auto">
              <b-pagination
                v-model="tableOptions.currentPage"
                :total-rows="tableTotalCount"
                :per-page="tableOptions.tablePageSize"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { personService } from "@/services";

export default {
  mixins: [personService],
  data() {
    return {
      searchString: "",
      tableOptions: {
        currentPage: 1,
        tablePageSize: 30,
      },
    };
  },
  computed: {
    items() {
      return this.searchPersons != null ? this.searchPersons.items : [];
    },
    tableTotalCount() {
      var item = this.searchPersons != null ? this.searchPersons : [];
      return item.totalCount;
    },
    tableRequestParams() {
      let requestParams = {
        searchString: this.searchString,
      };
      if (this.tableOptions.tablePageSize) {
        requestParams.pageNumber = this.tableOptions.currentPage;
        requestParams.pageSize = this.tableOptions.tablePageSize;
      }
      return requestParams;
    },
    fields() {
      return [
        { label: this.$t("common.name"), key: "fullName" },
        { label: this.$t("common.organisation"), key: "clubName" },
        {
          label: this.$t("common.federationMembershipIdentifier_krnb"),
          key: "federationMembershipIdentifier",
        },
      ];
    },
  },
  methods: {
    async setPerson(event) {
      this.searchString = event;
    },
    async searchPerson() {
      if (this.searchString != "") {
        await this.getSearchPersons({
          params: this.tableRequestParams,
        });
      }
    },
  },
  watch: {
    tableOptions: {
      deep: true,
      handler() {
        this.searchPerson();
      },
    },
  },
};
</script>

<style>
.list {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 55px;
  padding-right: 16px;
  margin: 0;
}

.non-active {
  background-color: #fff !important;
}

.search-person-input {
  width: 100% !important;
  border-radius: 0.25rem !important;
  border: 1px solid var(--gray-dark) !important;
}

.search-person-btn {
  background-color: var(--color-red);
  border-color: var(--color-red);
  color: #fff;
  margin-top: 0.5rem;
  border-radius: 0.25rem;
}

.search-person-btn:hover,
.search-person-btn:focus {
  background-color: var(--color-red);
  border-color: var(--color-red);
  color: #fff;
}

@media (max-width: 768px) {
  .list {
    margin-top: 0;
    padding-left: 16px;
  }
}
</style>
