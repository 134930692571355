<template>
  <article style="display: block;">
    <router-view :key="$route.fullPath" />
  </article>
</template>

<script>
export default {};
</script>

<style>
.card-header {
  background-color: transparent;
  margin-left: -10px;
  border: none;
  margin-left: -15px;
}

.tab-pane-border {
  border-top: 1px solid var(--light-blue);
}

.btn-primary,
.btn-primary:focus,
.btn-primary:hover {
  color: var(--light-blue);
  background-color: white;
  border-color: var(--light-blue);
  border-radius: inherit;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: white !important;
  background-color: var(--light-red) !important;
  border-color: var(--light-red) !important;
}

.btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover {
  color: var(--light-blue);
  background-color: red;
  border-color: var(--light-blue);
  border-radius: inherit;
}

.btn-secondary:active,
.btn-secondary.active,
.open > .dropdown-toggle.btn-secondary {
  color: red !important;
  background-color: var(--light-red) !important;
  border-color: var(--light-red) !important;
}

.badge {
  border-radius: 0px;
  width: 50px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--light-blue);
  height: 90px;
}

.img-container {
  display: block;
  margin: auto;
}

.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-15 {
  font-size: 15px;
}

.blue {
  color: var(--light-blue);
}

.color-b {
  color: black;
}

.nav-tabs .nav-link:hover {
  background-color: var(--light-red);
  border-top: 1px solid var(--light-blue);
  border-left: 1px solid var(--light-blue);
  border-right: 1px solid var(--light-blue);
  border-bottom: 1px solid var(--light-blue);
  border-radius: 0.5px;
  margin-left: 5px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: var(--light-red);
  text-decoration: underline;
  border-top: 1px solid var(--light-red);
  border-left: 1px solid var(--light-red);
  border-right: 1px solid var(--light-red);
  border-bottom: 1px solid var(--light-red);
  border-radius: 0.5px;
}

.tab-pane-border {
  border: none;
}

.card-body {
  margin-top: 10px;
  padding: 0px;
  background-color: transparent;
}

a:hover {
  text-decoration: underline;
  color: var(--light-red);
}

.custom-nav {
  border: none !important;
  background: transparent !important;
  color: var(--light-blue) !important;
}

.custom-nav:hover {
  text-decoration: underline !important;
}

.custom-nav.active {
  text-decoration: underline !important;
  font-weight: 600;
}

.fw-b-600 {
  font-weight: 600;
}

.input-search {
  width: 80%;
  max-width: 200px;
  border-style: solid !important;
  border-width: 1px !important;
}
</style>
