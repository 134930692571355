<template>
  <div class="ml-1">
    <tournament-days-card @fetchData="loadData" />
    <div class="ml-2 mt-4" v-if="matchesLoading">
      <h3>
        {{ $t("common.loading_matches") }}
        <div class="spinner-grow spinner-grow-sm mb-1" role="status" />
      </h3>
    </div>
    <b-table
      v-else
      :fields="fields"
      :items="matches"
      show-empty
      borderless
      sort-by="position"
      :empty-text="$t('common.no_records')"
      small
      responsive
    >
      <template v-slot:cell(matchCodeWithAddition)="{ item }">
        {{
          item.alternativeCode != null
            ? `${item.alternativeCode} ${
                item.codeAddition != null ? item.codeAddition : ""
              }`
            : item.matchCodeWithAddition
        }}
      </template>
      <template v-slot:cell(matchNameWithAddition)="{ item }">
        {{
          item.alternativeName != null
            ? `${item.alternativeName} ${
                item.nameAddition != null ? item.nameAddition : ""
              }`
            : item.matchNameWithAddition
        }}
      </template>
      <template v-slot:cell(tournamentTimeBlockName)="{ item }">
        {{ item.tournamentTimeBlockName | dash }}
      </template>
      <template v-slot:cell(isClassifing)="{ item }">
        <div v-if="item.isClassifing">
          {{ item.isClassifing | yesNo }}
        </div>
      </template>
      <template v-slot:cell(tournamentCostGroupPrice)="{ item }">
        {{ item.tournamentCostGroupPrice | currencyEuro }}
      </template>
      <template v-slot:cell(allowIndividualRegistration)="{ item }">
        <div v-if="item.allowIndividualRegistration">
          <b-button
            variant="outline-success"
            size="sm"
            @click="navigateMyEnvironment(item.id)"
            >{{ $t("common.individual_registration") }}
            <b-icon-arrow-right class="mr-1" />
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { matchesService } from "@/services";
import { appSettings } from "@/appSettings";

export default {
  mixins: [matchesService],
  data() {
    return {
      fields: [
        {
          label: this.$t("common.number"),
          key: "number",
          thClass: "color-b",
          thStyle: "width:100px",
        },
        {
          label: this.$t("common.event"),
          key: "matchCodeWithAddition",
          thClass: "color-b",
        },
        {
          label: "",
          key: "matchNameWithAddition",
          thClass: "color-b",
        },
        {
          label: this.$t("common.block"),
          key: "tournamentTimeBlockNumber",
          thClass: "color-b",
        },
        {
          label: this.$t("common.is_classified"),
          key: "isClassifing",
          thClass: "color-b",
        },
        {
          label: this.$t("common.fee"),
          key: "tournamentCostGroupPrice",
          thClass: "color-b",
        },
        {
          label: "",
          key: "allowIndividualRegistration",
          thClass: "color-b",
        },
      ],
    };
  },
  methods: {
    async loadData(tournamentDayId) {
      await this.getMatches({
        params: {
          tournamentId: this.tournamentId,
          tournamentDayId: tournamentDayId,
        },
      });
    },
    navigateMyEnvironment(matchId) {
      window.location.href = `${appSettings.MyEnvironmentUrl}/enroll-rowing-event/${matchId}`;
    },
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
};
</script>
