var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.matchTeamsLoading}},[_c('p',{staticClass:"font-weight-bold blue fs-14 mt-2 mb-2"},[_vm._v(" "+_vm._s(((_vm.totalRegistrations()) + " " + (_vm.$t("common.registrations_lowercase")) + " "))+" ")]),_c('h5',{staticClass:"color-b"},[_vm._v(_vm._s(_vm.organisationName))]),_c('tournament-days-card',{on:{"fetchData":_vm.loadData}}),_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.matchTeams,"show-empty":"","responsive":"","borderless":"","empty-text":_vm.$t('common.no_records'),"small":""},scopedSlots:_vm._u([{key:"cell(stroke)",fn:function(row){return [(_vm.stroke(row))?_c('router-link',{attrs:{"to":{
          name: 'ResultsPerPerson',
          params: { guid: _vm.stroke(row).personId },
        }}},[_vm._v(" "+_vm._s(_vm.stroke(row).fullName ? _vm.stroke(row).fullName : "")+" ")]):_vm._e()]}},{key:"cell(action)",fn:function(row){return [(row.item.matchRegistration)?_c('b-link',[(!row.detailsShowing)?_c('b-icon-chevron-down',{staticClass:"color-b",on:{"click":row.toggleDetails}}):_c('b-icon-chevron-up',{staticClass:"color-b",on:{"click":row.toggleDetails}})],1):_vm._e(),_c('b-form-checkbox',{staticClass:"d-none",on:{"change":row.toggleDetails},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}})]}},{key:"row-details",fn:function(row){return [_c('b-table',{staticClass:"table-sm",attrs:{"fields":_vm.tableDetailsFields,"items":row.item.teamVersions.filter(function (x) { return x.isActive; }),"responsive":""},scopedSlots:_vm._u([{key:"cell(organisationName)",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.organisationName))])]},proxy:true},{key:"cell(cox)",fn:function(row){return [(_vm.cox(row))?_c('router-link',{attrs:{"to":{
              name: 'ResultsPerPerson',
              params: { guid: _vm.cox(row).personId },
            }}},[_vm._v(" "+_vm._s(_vm.cox(row).fullName ? _vm.cox(row).fullName : "")+" ")]):_vm._e()]}},{key:"cell(rowers)",fn:function(row){return [_vm._l((row.item.teamMembers.filter(
              function (tm) { return tm.isBow; }
            )),function(teamMember){return _c('b-row',{key:_vm.keyCode(teamMember.personId)},[_c('router-link',{attrs:{"to":{
                name: 'ResultsPerPerson',
                params: { guid: teamMember.personId },
              }}},[_vm._v(" "+_vm._s(("b: " + (teamMember.fullName)))+" ")])],1)}),_vm._l((row.item.teamMembers
              .filter(function (tm) { return !tm.isBow && !tm.isStroke && tm.boatPosition; })
              .sort(function (a, b) { return (a.boatPosition > b.boatPosition ? 1 : -1); })),function(teamMember){return _c('b-row',{key:_vm.keyCode(teamMember.personId)},[_c('router-link',{attrs:{"to":{
                name: 'ResultsPerPerson',
                params: { guid: teamMember.personId },
              }}},[_vm._v(" "+_vm._s(((teamMember.boatPosition) + ": " + (teamMember.fullName)))+" ")])],1)}),_vm._l((row.item.teamMembers.filter(
              function (tm) { return tm.isStroke; }
            )),function(teamMember){return _c('b-row',{key:_vm.keyCode(teamMember.personId)},[_c('router-link',{attrs:{"to":{
                name: 'ResultsPerPerson',
                params: { guid: teamMember.personId },
              }}},[_vm._v(" "+_vm._s(("s: " + (teamMember.fullName)))+" ")])],1)}),_vm._l((row.item.teamMembers.filter(
              function (tm) { return tm.isCox; }
            )),function(teamMember){return _c('b-row',{key:_vm.keyCode(teamMember.personId)},[_c('router-link',{attrs:{"to":{
                name: 'ResultsPerPerson',
                params: { guid: teamMember.personId },
              }}},[_vm._v(" "+_vm._s(("st: " + (teamMember.fullName)))+" ")])],1)})]}},{key:"cell(boat)",fn:function(){return [_vm._v(" "+_vm._s(row.item.boatName)+" ")]},proxy:true},{key:"cell(coach)",fn:function(row){return [(_vm.coach(row))?_c('router-link',{attrs:{"to":{
              name: 'ResultsPerPerson',
              params: { guid: _vm.coach(row).personId },
            }}},[_vm._v(" "+_vm._s(_vm.coach(row).fullName ? _vm.coach(row).fullName : "")+" ")]):_vm._e()]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }