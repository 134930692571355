import { mapGetters, mapActions } from 'vuex';

export const tournamentDaysService = {
    computed: {
        ...mapGetters([
            'tournamentDays', 'tournamentDaysLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getTournamentDays'
        ])
    }
}