import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const tournamentTypes = new BaseStoreBuilder({
  baseURL: appSettings.BaseUrl,
  entityName: "tournamentType",
  resourceName: "tournament-types"
})
  .getEntitiesList()
  .build();

export default tournamentTypes;