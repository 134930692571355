<template>
  <div>
    <tournament-days-card @fetchData="loadData" />
    <div class="ml-2 mt-4" v-if="matchesLoading">
      <h3>
        {{ $t("common.loading_results") }}
        <div class="spinner-grow spinner-grow-sm mb-1" role="status" />
      </h3>
    </div>
    <b-table
      v-else
      :fields="fields"
      :items="matches"
      show-empty
      borderless
      :empty-text="$t('common.no_records')"
      small
      responsive
    >
      <template #cell(action)="row">
        <b-link v-if="row.item.races.length">
          <b-icon-chevron-down
            v-if="!row.detailsShowing"
            class="color-b"
            @click="row.toggleDetails"
          />
          <b-icon-chevron-up
            v-else
            class="color-b"
            @click="row.toggleDetails"
          />
        </b-link>
        <b-form-checkbox
          class="d-none"
          v-model="row.detailsShowing"
          @change="row.toggleDetails"
        >
        </b-form-checkbox>
      </template>
      <template #row-details="row">
        <result-details-card :races="row.item.races" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { matchesService } from "@/services";
import { ResultDetailsCard } from "@/app/RaceResults";

export default {
  mixins: [matchesService],
  components: { ResultDetailsCard },
  props: {
    filter: {
      type: Array,
      defaultValue: [],
    },
  },
  data() {
    return {
      fields: [
        { label: "#", key: "number", thClass: "color-b" },
        {
          label: this.$t("common.match_code"),
          key: "code",
          thClass: "color-b",
        },
        {
          label: "",
          key: "alternativeName",
          thClass: "color-b",
        },
        {
          label: "",
          key: "name",
          thClass: "color-b",
        },
        {
          label: this.$t("common.results"),
          key: "action",
          thClass: "color-b",
        },
      ],
    };
  },
  methods: {
    async loadData(tournamentDayId, fromNav) {
      if (this.filter && !fromNav) {
        this.$eventHub.$emit("update-tournament-day", this.filter[0].dayId);
        this.getMatches({
          params: {
            tournamentId: this.tournamentId,
            tournamentdayId: this.filter[0].dayId,
            raceResults: true,
            matchIds: this.filter[0].matchIds,
          },
        }).then((result) => {
          result.data.forEach((match) => {
            match.races.forEach((race) => {
              race.raceTeams.forEach((raceTeam) => {
                raceTeam.raceTeamTimes.sort(function(a, b) {
                  return a.distance - b.distance;
                });
              });
            });
          });
        });
      } else {
        this.getMatches({
          params: {
            tournamentId: this.tournamentId,
            tournamentdayId: tournamentDayId,
            raceResults: true,
          },
        }).then((result) => {
          result.data.forEach((match) => {
            match.races.forEach((race) => {
              race.raceTeams.forEach((raceTeam) => {
                raceTeam.raceTeamTimes.sort(function(a, b) {
                  return a.distance - b.distance;
                });
              });
            });
          });
        });
      }
    },
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
};
</script>
