import { mapGetters, mapActions } from 'vuex';

export const rankingsService = {
    computed: {
        ...mapGetters([
            'rankings', 'rankingsLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getRankings'
        ])
    }
}