var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.races),function(race){return _c('div',{key:_vm.keyCode(race.id)},[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('h5',{staticClass:"color-b"},[_vm._v(" "+_vm._s(_vm.formatDate(race.startTime, "HH:mm"))+" ")])]),_c('b-col',{attrs:{"cols":"10"}},[_c('h5',{staticClass:"color-b"},[_vm._v(_vm._s(race.name))])])],1),_c('b-row',[_c('p',{staticClass:"font-italic ml-3"},[_vm._v(_vm._s(race.environmentInformation))])]),_c('b-table-simple',{staticClass:"table-sm",attrs:{"responsive":""}},[_c('b-tr',[_c('b-th',{staticStyle:{"width":"2%"}}),_c('b-th',{staticStyle:{"width":"5%"}}),_c('b-th',{staticStyle:{"width":"10%","opacity":"50%"}},[_vm._v(" "+_vm._s(_vm.$t("common.organisation"))+" ")]),_c('b-th',{staticStyle:{"width":"10%","opacity":"50%"}},[_vm._v(" "+_vm._s(_vm.$t("common.team"))+" ")]),_c('b-th',{staticStyle:{"width":"5%","opacity":"50%"}},[_vm._v(_vm._s(_vm.$t("common.lane"))+" ")]),_c('b-th',{staticStyle:{"width":"30%"}}),_vm._l((_vm.getLongestListOfTimes(
            race.raceTeams.map(function (x) { return x.raceTeamTimes; })
          )),function(raceTeamTime){return _c('b-th',{key:_vm.keyCode(raceTeamTime.id),staticStyle:{"opacity":"50%","width":"10%"}},[(!raceTeamTime.isFinishTime)?[_vm._v(_vm._s(_vm._f("measurementMeter")(raceTeamTime.distance)))]:[_vm._v(_vm._s(_vm.$t("common.finish")))]],2)}),(
            race.raceTeams.some(function (x) { return x.bonusTime != null && x.bonusTime > 0; })
          )?_c('b-th',{staticStyle:{"width":"30%","opacity":"50%"}},[_vm._v(_vm._s(_vm.$t("common.correctedTime")))]):_vm._e()],2),_vm._l((race.raceTeams),function(raceTeam){return _c('b-tr',{key:_vm.keyCode(raceTeam.id),staticClass:"body",staticStyle:{"cursor":"pointer"},on:{"click":function($event){raceTeam._showDetails
            ? _vm.$set(raceTeam, '_showDetails', false)
            : _vm.$set(raceTeam, '_showDetails', true)}}},[_c('b-td',{staticClass:"pl-0 pt-2"},[(raceTeam.didNotFinish)?_c('span',{staticClass:"font-italic fs-12"},[_vm._v(" DNF ")]):(raceTeam.didNotStart)?_c('span',{staticClass:"font-italic fs-12"},[_vm._v(" DNS ")]):_c('span',[_vm._v(_vm._s(raceTeam.endPosition))])]),_c('b-td',{staticClass:"pl-4 pt-2"},[(raceTeam.oarBladeUrl)?_c('img',{staticClass:"center-block img-responsive",staticStyle:{"max-width":"50px","max-height":"30px"},attrs:{"src":raceTeam.oarBladeUrl,"fluid":""}}):_vm._e()]),_c('b-td',[_c('b-row',[_vm._v(_vm._s(raceTeam.teamVersion.matchTeam.organisationName))])],1),_c('b-td',[_c('b-row',[_vm._v(_vm._s(raceTeam.teamVersion.matchTeam.name))]),(raceTeam.teamVersion != null)?_c('b-row',_vm._l((raceTeam.teamVersion.teamMembers),function(teamMember){return _c('div',{key:_vm.keyCode(teamMember.id)},[_c('router-link',{attrs:{"to":{
                  name: 'ResultsPerPerson',
                  params: { guid: teamMember.person.personId },
                }}},[_vm._v(" "+_vm._s(teamMember.isStroke ? teamMember.fullName : "")+" ")])],1)}),0):_vm._e()],1),_c('b-td',{staticClass:"pl-3"},[_vm._v(_vm._s(raceTeam.lane))]),_c('b-td',{staticClass:"pl-4"},[(raceTeam._showDetails)?_c('div',[(raceTeam.teamVersion != null)?_c('b-row',[_c('b-col',[_c('b-row',[(_vm.bow(raceTeam.teamVersion.teamMembers))?_c('router-link',{attrs:{"to":{
                      name: 'ResultsPerPerson',
                      params: {
                        guid: _vm.bow(raceTeam.teamVersion.teamMembers).person
                          .personId,
                      },
                    }}},[_vm._v(" "+_vm._s(("b: " + (_vm.bow(raceTeam.teamVersion.teamMembers).fullName)))+" ")]):_vm._e()],1),_vm._l((_vm.rowers(raceTeam.teamVersion.teamMembers)),function(rower){return _c('b-row',{key:_vm.keyCode(rower.person.personId)},[_c('router-link',{attrs:{"to":{
                      name: 'ResultsPerPerson',
                      params: { guid: rower.person.personId },
                    }}},[_vm._v(" "+_vm._s(rower.boatPosition ? ((rower.boatPosition) + " " + (rower.fullName)) : "")+" ")])],1)}),_c('b-row',[(_vm.stroke(raceTeam.teamVersion.teamMembers))?_c('router-link',{attrs:{"to":{
                      name: 'ResultsPerPerson',
                      params: {
                        guid: _vm.stroke(raceTeam.teamVersion.teamMembers).person
                          .personId,
                      },
                    }}},[_vm._v(" "+_vm._s(("s: " + (_vm.stroke(raceTeam.teamVersion.teamMembers).person .fullName)))+" ")]):_vm._e()],1),_c('b-row',[(_vm.cox(raceTeam.teamVersion.teamMembers) != null)?_c('router-link',{attrs:{"to":{
                      name: 'ResultsPerPerson',
                      params: {
                        guid: _vm.cox(raceTeam.teamVersion.teamMembers).person
                          .personId,
                      },
                    }}},[_vm._v(" "+_vm._s(("st: " + (_vm.cox(raceTeam.teamVersion.teamMembers).fullName)))+" ")]):_vm._e()],1)],2),_c('b-col',[_vm._l((_vm.rowers(raceTeam.teamVersion.teamMembers)),function(rower){return _c('b-row',{key:_vm.keyCode(rower.person.personId)},[(rower.isCoach)?_c('router-link',{attrs:{"to":{
                      name: 'ResultsPerPerson',
                      params: {
                        guid: rower.person.personId,
                      },
                    }}},[_vm._v(" "+_vm._s(("coach: " + (rower.fullName)))+" ")]):_vm._e()],1)}),_c('b-row',[_vm._v(" "+_vm._s(raceTeam.boatName ? ("boat: " + (raceTeam.boatName)) : "")+" ")])],2)],1):_vm._e()],1):_vm._e()]),_vm._l((_vm.formatRaceTeamTimes(race, raceTeam)),function(raceTeamTime,index){return _c('b-td',{key:_vm.keyCode(raceTeamTime.id),staticClass:"pl-3"},[_c('b-row',[_vm._v(_vm._s(_vm.computeTime(raceTeamTime.time)))]),_c('b-row',{staticClass:"ml-10 fs-10"},[_vm._v(_vm._s(index !== 0 ? _vm.computeTime(raceTeamTime.splitTime) : ""))])],1)}),(raceTeam.bonusTime > 0)?_c('b-td',{staticClass:"pl-3"},[_c('b-row',[_vm._v(_vm._s(_vm.computeTime(raceTeam.correctedEndTime)))]),_c('b-row',{staticClass:"ml-10 fs-10"},[_vm._v("+"+_vm._s(_vm.computeTime(raceTeam.bonusTime)))])],1):_vm._e()],2)})],2)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }