import { mapGetters, mapActions } from 'vuex';

export const racesService = {
    computed: {
        ...mapGetters([
            'races', 'racesLoading',
            'racesOverview', 'racesOverviewLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getRaces',
            'getRacesOverview'
        ])
    }
}