import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const rankingTournaments = new BaseStoreBuilder({
  baseURL: appSettings.BaseUrl,
  entityName: "rankingTournament",
  resourceName: "rankingTournaments"
})
  .getEntitiesList()
  .getEntityById()
  .build();

export default rankingTournaments;