<template>
  <b-overlay :show="racesLoading">
    <div class="mt-2" v-for="race in races" :key="keyCode(race.id)">
      <b-row>
        <b-col cols="2">
          <h5 class="color-b">{{ formatDate(race.startTime, "HH:mm") }}</h5>
        </b-col>
        <b-col cols="2">
          <h5 class="color-b">{{ race.name }}</h5>
        </b-col>
      </b-row>
      <b-row>
        <p class="font-italic ml-2">{{ race.environmentInformation }}</p>
      </b-row>
      <b-table-simple borderless fixed class="table-sm">
        <b-thead>
          <b-tr>
            <b-th style="width: 50px"></b-th>
            <b-th style="width: 100px"> </b-th>
            <b-th style="width: 50px; opacity: 50%">{{
              $t("common.lane")
            }}</b-th>
            <b-th
              style="width: 100px; opacity: 50%"
              v-for="raceTeamTime in race.raceTeams.map(
                (x) => x.raceTeamTimes
              )[0]"
              :key="keyCode(raceTeamTime.id)"
            >
              <template v-if="!raceTeamTime.isFinishTime">{{
                raceTeamTime.distance | measurementMeter
              }}</template>
              <template v-else>{{ $t("common.finish") }}</template>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(raceTeam, index) in race.raceTeams"
            :key="keyCode(index)"
          >
            <b-td>{{ raceTeam.number }}</b-td>
            <b-td>
              <b-row>{{ raceTeam.name }} </b-row>
              <b-row>
                <div
                  v-for="registration in race.match.registrations"
                  :key="keyCode(registration.id)"
                >
                  <div
                    v-for="team in registration.teams"
                    :key="keyCode(team.id)"
                  >
                    <div
                      v-for="teamVersion in team.teamVersions"
                      :key="keyCode(teamVersion.id)"
                    >
                      <div
                        v-for="teamMember in teamVersion.teamMembers"
                        :key="keyCode(teamMember.id)"
                      >
                        <router-link
                          :to="{
                            name: 'ResultsPerPerson',
                            params: { guid: teamMember.personId },
                          }"
                        >
                          {{ teamMember.isStroke ? teamMember.fullName : "" }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </b-row>
            </b-td>
            <b-td>{{ raceTeam.lane }}</b-td>
            <b-td
              v-for="raceTeamTime in raceTeam.raceTeamTimes"
              :key="keyCode(raceTeamTime.id)"
            >
              <b-row>{{ computeMinutes(raceTeamTime.time) }}</b-row>
              <b-row>{{ computeMinutes(raceTeam.bonus) }}</b-row>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div v-if="!races.length" class="text-center">
      {{ $t("common.no_records") }}
    </div>
  </b-overlay>
</template>

<script>
import { addSeconds } from "date-fns";
import { racesService } from "@/services";

export default {
  mixins: [racesService],
  methods: {
    computeMinutes(currentMilestone) {
      let defaultDate = new Date(0);
      let formattedDate = this.formatDate(
        addSeconds(defaultDate, currentMilestone),
        "mm:ss"
      );
      return formattedDate;
    },
    async loadData() {
      await this.getRaces({
        params: {
          tournamentId: this.tournamentId,
        },
      }).then((result) => {
        result.data.forEach((race) => {
          race.raceTeams.forEach((raceTeam) => {
            raceTeam.raceTeamTimes.sort(function (a, b) {
              return a.distance - b.distance;
            });
          });
        });
      });
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped>
th:nth-child(4),
td:nth-child(4) {
  border-left: 1px solid var(--light-blue);
}

td {
  margin-left: 5px;
  padding-left: 30px;
  padding-top: 2px;
  padding-right: 5px;
  padding-bottom: 5px;
}

tr:hover {
  background-color: #d2d3f0;
}
</style>