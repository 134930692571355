<template>
  <div v-if="clubRacesView">
    <p
      v-if="!organisationsLoading"
      class="font-weight-bold blue fs-14 mt-2 mb-2"
    >
      {{ `${totalRegistrations()} ${$t("common.registrations_lowercase")} ` }}
    </p>

    <div class="ml-2 mt-4" v-if="organisationsLoading">
      <h3>
        {{ $t("common.loading_organisations") }}
        <div class="spinner-grow spinner-grow-sm mb-1" role="status" />
      </h3>
    </div>
    <b-table
      v-else
      :fields="fields"
      :items="organisations"
      show-empty
      borderless
      small
      responsive
      :empty-text="$t('common.no_records')"
    >
      <template #cell(organisationName)="row">
        <b-link @click="navigateToClubDetailsCard(row.item)">{{
          row.item.organisationName
        }}</b-link>
      </template>
      <template #row-details="row">
        <registration-details-card
          :registrations="row.item.registrations"
          :base-row="row"
        />
      </template>
      <template #cell(registrationCount)="team">
        {{ team.item.match.registrationCount }}
      </template>
    </b-table>
  </div>
  <div v-else>
    <registrations-by-club-details-card
      :organisationName="clubName"
      :organisationId="organisationId"
    />
  </div>
</template>

<script>
import { organisationsService } from "@/services";
import { RegistrationsByClubDetailsCard } from "@/app/Registrations";
import { RegistrationDetailsCard } from "@/app/Registrations";

export default {
  mixins: [organisationsService],
  components: { RegistrationsByClubDetailsCard, RegistrationDetailsCard },
  data() {
    return {
      clubName: "",
      clubRacesView: true,
      fields: [
        {
          label: this.$t("common.name"),
          key: "organisationName",
          thClass: "color-b",
        },
        {
          label: this.$t("common.#_registrations"),
          key: "count",
          thClass: "color-b",
        },
      ],
    };
  },
  methods: {
    navigateToClubDetailsCard(item) {
      this.clubName = item.organisationName;
      this.organisationId = item.organisationId;
      this.clubRacesView = false;
    },
    totalRegistrations() {
      return this.organisations.map((x) => x.count).reduce((a, b) => a + b, 0);
    },
    async loadData() {
      this.getOrganisations({
        params: {
          tournamentId: this.tournamentId,
          onlyRegistrations: true,
        },
      });
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
};
</script>
