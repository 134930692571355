<template>
  <b-container fluid style="padding-top: 140px;">
    <b-overlay :show="racesOverviewLoading">
      <b-row class="mt-5">
        <b-col cols="4">
          <person-details-info :data="personDetailsInfo" />
        </b-col>
        <b-col>
          <person-overview-results :data="personOverviewResults" />
        </b-col>
      </b-row>
      <b-row class="tournament-results">
        <div
          class="mt-2"
          v-for="(raceOverview, index) in racesOverview"
          :key="index"
        >
          <h6 class="font-weight-bold color-b">
            {{ raceOverview.tournamentName }}
          </h6>
          <div class="fs-15 font-italic">
            <p style="opacity: 50%">
              {{
                ` ${formatDate(
                  raceOverview.firstTournamentDate,
                  "dd"
                )} - ${formatDate(
                  raceOverview.lastTournamentDate,
                  "dd MMM yyyy"
                )} `
              }}
            </p>
          </div>
          <b-table
            :fields="fields"
            :items="raceOverview.raceResults"
            show-empty
            borderless
            fixed
            :empty-text="$t('common.no_records')"
            small
            responsive
          >
            <template #cell(matchName)="row">
              <span style="inline-size: 150px; overflow-wrap: break-word;">
                {{ row.item.matchName }}
              </span>
            </template>
            <template #cell(teamName)="row">
              <span style="inline-size: 150px; overflow-wrap: break-word;">
                {{ row.item.teamName }}
              </span>
            </template>
            <template #cell(raceName)="row">
              <b-link
                style="inline-size: 150px; overflow-wrap: break-word;"
                :href="
                  `/tournament-details/${row.item.tournamentId}?tab=results&subMenu=race&raceId=${row.item.raceId}&tournamentDayId=${row.item.tournamentDayId}`
                "
              >
                {{ row.item.raceName }}
              </b-link>
            </template>
            <template #cell(stroke)="row">
              <span style="inline-size: 150px; overflow-wrap: break-word;">
                {{ row.item.stroke ? row.item.stroke.fullName : "" }}
              </span>
            </template>
            <template #cell(endTime)="row">
              {{ computeTime(row.item.endTime) }}
            </template>
            <template #cell(bow)="row">
              <router-link
                :to="{
                  name: 'ResultsPerPerson',
                  params: { guid: row.item.bow ? row.item.bow.personId : '' },
                }"
              >
                {{ row.item.bow ? row.item.bow.fullName : "" }}
              </router-link>
            </template>
          </b-table>
        </div>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<style scoped>
.tournament-results {
  border-top: 1px solid var(--light-blue);
}
</style>

<script>
import { racesService, personService } from "@/services";
import { PersonDetailsInfo, PersonOverviewResults } from "@/app/Person";
import { addSeconds } from "date-fns";

export default {
  mixins: [racesService, personService],
  components: { PersonDetailsInfo, PersonOverviewResults },
  data() {
    return {
      personDetailsInfo: {},
      personOverviewResults: [],
      tournaments: [],
      fields: [
        {
          label: "#",
          key: "matchNumber",
          thClass: "color-b",
        },
        {
          label: this.$t("common.code"),
          key: "matchCode",
          thClass: "color-b",
        },
        {
          label: "",
          key: "matchName",
          thClass: "color-b",
        },
        {
          label: this.$t("common.team"),
          key: "teamName",
          thClass: "color-b",
        },
        {
          label: this.$t("common.stroke"),
          key: "stroke",
          thClass: "color-b",
        },
        {
          label: this.$t("common.race"),
          key: "raceName",
          thClass: "color-b",
        },
        {
          label: this.$t("common.lane"),
          key: "lane",
          thClass: "color-b",
        },
        {
          label: this.$t("common.time"),
          key: "endTime",
          thClass: "color-b",
        },
        {
          label: this.$t("common.position"),
          key: "position",
          thClass: "color-b",
        },
      ],
    };
  },
  methods: {
    computeTime(currentMilestone) {
      var minutes = Math.floor(currentMilestone / 60);
      var seconds = currentMilestone - minutes * 60;
      return `${minutes}:${
        seconds.toFixed(2) < 10 ? `0${seconds.toFixed(2)}` : seconds.toFixed(2)
      }`;
    },
    async loadData(searchString, seasonId) {
      await this.getRacesOverview({
        params: {
          personId: this.personId,
        },
      }).then((resp) => {});

      await this.getPerson({
        params: {
          id: this.personId,
        },
      }).then((resp) => {
        this.personDetailsInfo = {
          fullName: resp.data.fullName,
          organisationName: resp.data.organisationName,
          yearOfBirth: resp.data.yearOfBirth,
        };
        this.personOverviewResults = {
          scull: resp.data.totalScullingPoints,
          boord: resp.data.totalSweepingPoints,
          rowingPoints: resp.data.rowingPoints,
        };
      });
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    personId() {
      return this.$route.params.guid;
    },
  },
};
</script>
