import { mapGetters, mapActions } from 'vuex';

export const organisationsService = {
    computed: {
        ...mapGetters([
            'organisations', 'organisationsLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getOrganisations'
        ])
    }
}