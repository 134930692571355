import Vue from "vue";
import VueRouter from "vue-router";
import { TournamentsOverviewPage } from "@/app/Tournaments";
import { TournamentDetailsPage } from "@/app/Tournaments";
import { ResultsPerPersonPage } from "@/app/Person";
import { PersonSearchPage } from "@/app/Person";
import { RankingsOverviewPage } from "@/app/Rankings";
import { RankingDetailsPage } from "@/app/Rankings";
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "/AgendaOverview",
    component: TournamentsOverviewPage,
  },
  {
    path: "/tournament-details/:id",
    name: "TournamentDetails",
    component: TournamentDetailsPage,
    props: (route) => ({
      tab: route.query.tab ? route.query.tab : "details",
      subMenu: route.query.subMenu ? route.query.subMenu : "matrix",
      raceId: route.query.raceId ? parseInt(route.query.raceId) : 0,
      tournamentDayId: route.query.tournamentDayId
        ? parseInt(route.query.tournamentDayId)
        : 0,
    }),
  },
  {
    path: "/person-results/:guid",
    name: "ResultsPerPerson",
    component: ResultsPerPersonPage,
  },
  {
    path: "/person-search",
    name: "PersonSearch",
    component: PersonSearchPage,
  },
  {
    path: "/rankings",
    name: "RankingsOverview",
    component: RankingsOverviewPage,
  },
  ,
  {
    path: "/rankings/:id",
    name: "RankingDetails",
    component: RankingDetailsPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
